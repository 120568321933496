import React, { useEffect, useState, Suspense } from 'react'

import { Container } from 'react-bootstrap'
import Header from '../components/Header'
import ScrollIndicator from '../components/ScrollIndicator';
import Footer from '../components/Footer';
import Fade from 'react-reveal/Fade';
import config from 'react-reveal/globals';
import "../css/landing.css"
import { Helmet } from 'react-helmet';
import lazyname from "react-lazy-named";
import SearchBarDesktop from '../components/SearchBarDesktop';

const FirstSection = lazyname(() => import('../OptimRepack/LandingPageComponents'), 'FirstSection');
const Stats = lazyname(() => import('../OptimRepack/LandingPageComponents'), 'Stats');
const Featured = lazyname(() => import('../OptimRepack/LandingPageComponents'), 'Featured');
const DarkBand = lazyname(() => import('../OptimRepack/LandingPageComponents'), 'DarkBand');
const CuratedSection = lazyname(() => import('../OptimRepack/LandingPageComponentsBottom'), 'CuratedSection');
const Testimonials = lazyname(() => import('../OptimRepack/LandingPageComponentsBottom'), 'Testimonials');
const ScheduleVisit = lazyname(() => import('../OptimRepack/LandingPageComponentsBottom'), 'ScheduleVisit');
const Reviews = lazyname(() => import('../OptimRepack/LandingPageComponentsBottom'), 'Reviews');
const Partners = lazyname(() => import('../OptimRepack/LandingPageComponentsBottom'), 'Partners');
const Subscribe = lazyname(() => import('../OptimRepack/LandingPageComponentsBottom'), 'Subscribe');


config({ ssrFadeout: true });

export default function Landing() {

    useEffect(() => {
        const { hash } = window.location;
        if (hash !== '') {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) element.scrollIntoView();
            }, 0);
        }
        else {
            window.scrollTo(0, 0);
        }
    }, [window.location]);

    const searchbox = {
        "@context": "https://schema.org",
        "@type": "WebSite",
        "url": "https://" + window.location.hostname,
        "potentialAction": {
            "@type": "SearchAction",
            "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://" + window.location.hostname + `/searchbox/{search_term_string}`
            },
            "query-input": "required name=search_term_string"
        }
    }

    return (
        <>
            <Helmet>‍
                <title>Home Konnect® | Connecting to your dream home</title>‍
                <meta name="description" content={"Home Konnect® is a boutique CRISIL Rated & RERA Certified Real estate advisory with a refreshingly honest approach. At Home Konnect™, we provide end-to-end property solutions for home buyers, owners, lessees and lessors. Our expert team of Real Estate advisors is known for forging & nurturing long standing relationships built on trust, transparency, ethics, professionalism & our industry expertise. "} />‍
                <meta property="og:image" itemprop="image" content={"Images/banners/1.webp"} />‍
                
            </Helmet>
            <script type="application/ld+json">
                {JSON.stringify(searchbox)}
            </script>
            <div className="hero">
                <Header thresh={1} />
                <div className="hero-bg">
                    <div style={{ backgroundImage: 'url(Images/banners/1.webp)' }} className="hero-bg-img">

                    </div>
                    <div onClick={() => {
                        window.scrollTo(0, window.innerHeight)
                    }} className="scroll-indicator">
                        <ScrollIndicator />
                    </div>
                </div>


                <div style={{ zIndex: '1' }} className="hero-title">
                    <center>

                        <Fade top>
                            <div style={{ zIndex: '2', position: 'relative', width: '100%', zIndex: "2", paddingBottom: '2em' }} className="d-block d-md-none">
                                <SearchBarDesktop id="mobile" center={true} stroke="white" />
                            </div>
                        </Fade>
                        <div className="home-big-things" style={{ zIndex: '1', maxWidth: "900px" }}>
                            <Fade top>
                                <h2 className="home-delight" style={{ textTransform: "capitalize", letterSpacing: '0', marginBottom: '0.5em' }}>
                                    0% brokerage*. 100% delight
                                </h2>
                            </Fade>
                            <Fade left>
                                <h1 className="hero-big-text">
                                    Connecting to your dream home
                                </h1>
                            </Fade>
                            <Fade bottom>
                                <div className="capital-text" style={{ marginBottom: '1em', color: "var(--accent)", fontSize: '0.8rem' }}>
                                    Chennai's most trusted real estate agency
                                </div>
                            </Fade>


                            <Fade bottom>
                                <div className="d-none d-md-block">
                                    <SearchBarDesktop />
                                </div>
                            </Fade>
                        </div>

                    </center>
                </div>
            </div>
            <div style={{ zIndex: 0 }} className="normal-home home-first">


                <Suspense fallback={<></>}>
                    <FirstSection />
                </Suspense>

                <Container style={{ backgroundColor: "var(--gray)", marginTop: '0px' }} fluid>
                    <Suspense fallback={<></>}>
                        <Stats />
                    </Suspense>
                </Container>
                <Container>
                    <Suspense fallback={<></>}>
                        <Featured />
                    </Suspense>
                </Container>

                <Suspense fallback={<></>}>
                    <DarkBand />
                </Suspense>
                <Suspense fallback={<></>}>
                    <CuratedSection />
                </Suspense>
                <Suspense fallback={<></>}>
                    <Testimonials />
                </Suspense>
                <Suspense fallback={<></>}>
                    <ScheduleVisit />
                </Suspense>

                <Suspense fallback={<></>}>
                    <Reviews />
                </Suspense>

                <Suspense fallback={<></>}>
                    <Partners />
                </Suspense>
                <Suspense fallback={<></>}>
                    <Subscribe />
                </Suspense>
                

                <Footer />
            </div>


        </>
    )
}
