import "../css/scroll.css"

export default function ScrollIndicator() {
    const id = (Math.random() + 1).toString(36).substring(7);
    
    return (
        <svg className="scroll-group" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" width="74px" height="40px" viewBox="0 0 74 40" enableBackground="new 0 0 74 40" xmlSpace="preserve">
            <g >
                {/* <circle  opacity="0.5" fill="none" stroke="var(--white)" cx="71%" cy="50%" r="24%" >
                </circle> */}
                <circle className="unwanted" fill="none" stroke={"var(--accent)"} cx="71%" cy="50%" r="24%"></circle>

                <circle className="wanted" fill="none" strokeWidth="2" stroke={"url(#scroll-grad" + id + ")"} cx="71%" cy="50%" r="24%"></circle>
                {/* <circle fill="none" stroke="var(--white)" cx="72%" cy="51%" r="24%"></circle> */}

            </g>
            <polygon id={"scroll-arrow" + id}fill="var(--accent)" points="49.525,14.265 48.898,15.044 54.481,19.541 6.444,19.541 6.444,20.541 54.464,20.541 48.901,24.954 49.522,25.737 56.7,20.044 ">
            </polygon>

            <defs>
                <linearGradient id={"scroll-grad" + id} x1="100%" y1="50%" x2="0%" y2="50%">
                    <stop offset="0%" style={{stopColor: "var(--accent)", stopOpacity: 0.3 }}  />
                    <stop offset="100%" style={{stopColor: "var(--accent)", stopOpacity: 1 }}/>
                </linearGradient>
                <text x="100" y="120" text-anchor="middle" id="myText" font-size="50">Hello</text>
            </defs>
        </svg>
    )
}
