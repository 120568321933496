import React from 'react'
import { useParams } from 'react-router'
import { Redirect } from 'react-router'
export default function SearchRedirect(props) {
    const {text} = useParams()

    return (<Redirect to={'/search/{"text": "' + text +'"}'}>

    </Redirect>
    )
}
