import React, { useEffect, useState } from 'react'
import { NextArrow, PrevArrow } from './SlideArrows';
import { PropertyGrid } from './GridView';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



const SliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    dotsClass: "slick-dots slick-thumb soorkie-dots",
    responsive: [
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 769,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ],
    nextArrow: ((<NextArrow />)),
    prevArrow: ((<PrevArrow />))
};

export default function PreviewGrids(props) {
    const settings = SliderSettings;

    var temp_link = "https://images.unsplash.com/photo-1595850214858-3589454eb2b3?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzZ8fGhvdXNlJTIwbHV4dXJ5fGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60";

    var data = props.data;

    const width = window.innerWidth;
    const [height, setHeight] = useState(null);

    function renorm() {
        var objs = document.getElementsByClassName("soorkie-class");
        if (objs.length < 1) return;
        var max_height = 0;
        for (var i = 0; i < objs.length; i++) {
            max_height = Math.max(max_height, objs[i].getBoundingClientRect().height);
        }
        setHeight(max_height);
    }
    useEffect(() => {
        var objs = document.getElementsByClassName("soorkie-class");
        if (objs.length < 1) return;
        
    }, [])

    var neo = height ? { height: height } : {}
    var items = data.map((p, i) => {
        var margins = (width > 768) ? (((i % 3) != 0) ? { marginLeft: '20px' } : { marginRight: '20px' }) : {};
        return (
            <div>
                <div className="soorkie-class" style={{ margin: ((width < 768) ? "10px" : '10px') , ...neo}}>
                    <PropertyGrid same_tab={props.same_tab} callback={renorm} data={p} />
                </div>
            </div >
        )

    })

    const iter = (width > 1300) ? 3 : (width > 769) ? 2 : 1
    while (items.length < iter) {
        items.push(<></>)
    }

    return (<Slider {...settings}>{items}</Slider>);
}
