import React, { useEffect, useState, useRef } from 'react'
import { MapContainer, TileLayer, Marker, Tooltip, FeatureGroup } from 'react-leaflet';
import L from 'leaflet';
import { calcCrow } from '../contexts/DataContext';
import "../css/maps.css"

let DefaultIcon = L.icon({
    iconUrl: "/Images/marker.png",
    iconSize: [30, 40], // size of the icon
    iconAnchor: [15, 40]
});


let locationIcon = L.icon({
    iconUrl: "/Images/nearby.png",
    iconSize: [32, 32], // size of the icon
    iconAnchor: [16, 16]
});



L.Marker.prototype.options.icon = DefaultIcon;


function PrintDistance(distance) {
    distance = parseFloat(distance);
    if (distance > 1) return distance.toString().slice(0, 3) + " Km"
    else {
        distance = distance * 1000;
        return distance.toString().slice(0, 3) + " Meters";
    }
}


export function LocationMap(props) {
    const [nearby, setnearby] = useState([]);
    const position = [props.lattitude, props.longitude]
    const [markers, setMarkers] = useState([]);
    const [mapState, setMapState] = useState();
    const group = useRef();

    const mapRef = useRef();

    useEffect(() => {
        if (props.nearby && props.nearby.length > 0) setnearby(props.nearby)
        setMarkers(props.nearby.filter(p => (p.location1.latitude && p.location1.longitude) ? true : false).map((p, idx) => {
            const temp = [p.location1.latitude, p.location1.longitude]
            return (<Marker
                icon={locationIcon}
                key={`marker-${idx}`} position={temp}

            >
                <Tooltip direction="bottom" offset={[0, 20]}  >

                    <center>
                        <div className='other-label'>
                            <b>{p.location_type}</b> <br></br>({PrintDistance(calcCrow(p.location1.latitude, p.location1.longitude, position[0], position[1]))})
                        </div>
                    </center>
                </Tooltip>
            </Marker>)
        }));
        if (mapRef.current && group.current) {
            try {
                mapRef.current.fitBounds(group.current.getBounds())
            }
            catch (err) {
                console.error(err)
            }
        }
    }, [props])

    // (markers)
    return (
        <MapContainer whenCreated={mapInstance => { mapRef.current = mapInstance }} style={{ zIndex: '3' }} className={!props.flat ? "map-container" : "map-container-flat"} center={position} zoom={15} maxZoom={16} scrollWheelZoom={false}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                subdomains='abcd'
                minZoom={0}
                maxZoom={18}
                ext='png'
                url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
            />
            <FeatureGroup ref={group}>
                {(position[0] && position[1]) && (<Marker position={position}>
                    <Tooltip direction="bottom" offset={[0, 0]} opacity={1} permanent>

                        <div className="map-glow">

                        </div>

                    </Tooltip>
                    {props.label && (<Tooltip direction="top" offset={[0, -20]} opacity={1} permanent>
                        <center>
                            <div className="map-label">
                                {props.label}
                            </div>
                        </center>
                    </Tooltip>)}
                    {props.property_name && (<Tooltip direction="top" offset={[0, -20]}>
                        <center>
                            <div className="map-label2">
                                {props.property_name}
                            </div>
                        </center>
                    </Tooltip>)}
                </Marker>)}

                {markers}
            </FeatureGroup>
        </MapContainer>
    );
}
