import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import { getFavList } from '../classes/ContentProvider'
import "../css/fav.css"

export default function Fav(props) {
    const { getFavorites } = useAuth();
    const [num, setNum] = useState(0);
    useEffect(async() => {
        const bc = new BroadcastChannel('fav');
        bc.onmessage = (ev) => {
            var favs = JSON.parse(ev.data);
            
            favs = [...new Set(favs.filter(p => p != null || p != undefined))]
            setNum(favs.length)
        }

        const fav = [...new Set(getFavorites())];
        const stuff = await getFavList(fav);
        setNum(stuff.results.length)
    }, [window.fav])

    return (
        <Link title="Shortlist" style={{marginTop: 'auto', marginBottom: 'auto'}} to="/shortlists">
            <div  className="fav-class">
                <div className="heart-icon">
                    <svg width="22" height="22" viewBox="0 0 34 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.7201 28.8364L16.7201 28.8364L16.7156 28.8327C10.2581 23.5766 6.19776 20.0568 3.74674 17.1131C1.32204 14.201 0.5 11.8861 0.5 9.02051C0.5 4.29665 4.27614 0.520508 9 0.520508C12.8742 0.520508 15.0467 2.67563 16.6263 4.45269L17 4.87311L17.3737 4.45269C18.9533 2.67563 21.1258 0.520508 25 0.520508C29.7239 0.520508 33.5 4.29665 33.5 9.02051C33.5 11.8861 32.678 14.201 30.2533 17.1131C27.8022 20.0568 23.7419 23.5766 17.2844 28.8327L17.2843 28.8327L17.2799 28.8364L17 29.0697L16.7201 28.8364Z" strokeWidth="1.5" stroke="white" />
                    </svg>
                    <div className={(num) ? "bacon" : 'bacon-hide'}>{Math.max(1, num)}</div>
                </div>
            </div>
        </Link>
    )
}
