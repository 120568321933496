import React from 'react'
import { Link } from 'react-router-dom';
import "../css/google.css";


export default function PhoneSignin(props) {

    const phone = (<svg width="30" height="30" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.80401 11.8458C10.0681 13.1281 11.5976 14.1181 13.2851 14.7464L15.568 12.9297C15.6357 12.8831 15.716 12.8581 15.7982 12.8581C15.8803 12.8581 15.9606 12.8831 16.0283 12.9297L20.2671 15.6607C20.4281 15.7575 20.5643 15.8903 20.665 16.0488C20.7658 16.2072 20.8284 16.3869 20.8477 16.5737C20.8671 16.7605 20.8428 16.9492 20.7767 17.1249C20.7106 17.3007 20.6046 17.4587 20.467 17.5864L18.4808 19.5484C18.1963 19.8294 17.8467 20.0357 17.4631 20.1488C17.0796 20.2618 16.674 20.2782 16.2826 20.1963C12.3781 19.3899 8.77927 17.5013 5.89736 14.7464C3.07941 11.964 1.1294 8.42368 0.283881 4.55489C0.200355 4.16954 0.217788 3.76915 0.334491 3.39252C0.451194 3.01588 0.663209 2.67579 0.94999 2.40518L3.00887 0.418964C3.13628 0.288036 3.29166 0.187627 3.46337 0.125257C3.63508 0.0628871 3.81867 0.0401712 4.00041 0.0588106C4.18214 0.0774501 4.35731 0.136962 4.51278 0.23289C4.66826 0.328818 4.80002 0.458676 4.8982 0.612741L7.72007 4.79711C7.76875 4.86282 7.79502 4.94243 7.79502 5.0242C7.79502 5.10597 7.76875 5.18557 7.72007 5.25128L5.86103 7.48577C6.50629 9.1395 7.5116 10.6288 8.80401 11.8458V11.8458Z" fill="#0080FF" />
    </svg>)
    return (<>
        <Link style={{ textDecoration: "none", color: "black" }} target="_blank" className="google-signin-wrap" to="/phone">
            <div style={{ width: '30px', height: '30px' }} className="icon-signin">
                {phone}

            </div>
            <div className="capital-text text-signin">
                {props.value}
            </div>
        </Link>
    </>
    )
}
