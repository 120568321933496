import React, { useState, useEffect, useRef } from 'react'
import { Container, Row, Col, InputGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import "../css/footer.css"
import { fallback_city } from '../contexts/DataContext';
import {
    facebook_icon_small_dark,
    insta_small_dark,
    linkedin_icon_small_dark,
    utube_small_dark,
    msg_logo_tiny,
    phone_icon_tiny,
    whatsapp_icon_tiny,
    enq_button_tiny,
    twitter_icon_small_dark,
    pinterest_icon_small_dark
} from './Icons';

import ShowHide from './ShowHide';
import { LocationMap } from './Maps';

function chat_invoke() {
    try {
        document.getElementsByClassName("siq_bR")[0].click();
    }
    catch (err) {
    }
}

const whatsapp_message = "Hi, I would like to know more about your services.";


export function OfficeMap() {
    const [data, setData] = useState({});

    useEffect(async () => {
        setData(await window.site_data)
    }, [])

    return (
        <Container fluid style={{ padding: 0, filter: 'brightness(0.8)' }}>
            <LocationMap label="Home Konnect | CRISIL Rated Real Estate Advisory" nearby={[]} flat={true} lattitude={(data.location && data.location.latitude) || 0} longitude={(data.location && data.location.longitude) || 0} />
        </Container>
    )
}



export default function Footer() {
    const [allcities, setAllCities] = useState([]);
    const [data, setData] = useState({});
    const [collection, setCollection] = useState();

    useEffect(async () => {
        const cities = await window.cities;
        setAllCities(cities.filter(p => p.city_name != fallback_city));
        const s_data = await window.site_data;
        setData(s_data);
        setCollection(await window.collections);

    }, [])

    const date = new Date();
    const year = date.getFullYear();

    var redir = window.location.toString();
    redir = redir.replace(window.location.hash, "")
    redir = redir + "?submission=success";

    return (
        <Container style={{ zIndex: '0', position: 'relative', paddingLeft: '0', paddingRight: "0" }} className="footer-class" fluid>

            <Container style={{ paddingTop: '7em', paddingBottom: '5em' }}>
                <Row>
                    <Col lg={12}>
                        <h6 style={{ whiteSpace: 'nowrap', textAlign: 'center', fontSize: '170%', marginBottom: '0' }}>
                            Home konnect&trade;
                        </h6>
                    </Col>
                </Row>
                <Row className=" d-md-flex">
                    <Col md={12}>
                        <div className="capital-text footer-top">
                            Top Links to find your dream home
                        </div>
                    </Col>
                    <Col md={4} >
                        <ShowHide stroke="rgba(255,255,255,0.4)" ignore={window.innerWidth >= 768}
                            title={(<div className="capital-text footer-sub" >
                                Properties by city
                            </div>)
                            }>
                            {allcities.map(p => {
                                return (
                                    <Link style={{ textDecoration: "none" }} to={"/property/" + p.city_name}>
                                        <div className="footer-item">
                                            Properties in {p.city_name}
                                        </div>
                                    </Link>
                                )
                            })}
                            <Link style={{ textDecoration: 'none' }} to={'/search/{"city":["All cities"]}'} >
                                <div className="footer-item">
                                    Properties in {fallback_city}
                                </div>
                            </Link>
                        </ShowHide>

                    </Col>
                    <Col md={4}>
                        <ShowHide stroke="rgba(255,255,255,0.4)" ignore={window.innerWidth >= 768} title={(<div className="capital-text footer-sub" >
                            Properties by type
                        </div>)}>
                            <Link style={{ textDecoration: 'none' }} to={'/search/{"property_type":["Apartments"]}'} >
                                <div className="footer-item">
                                    Apartments for sale
                                </div>
                            </Link>
                            <Link style={{ textDecoration: 'none' }} to={'/search/{"property_type":["Villa"]}'} >
                                <div className="footer-item">
                                    Villas & Row Houses
                                </div>
                            </Link>
                            <Link style={{ textDecoration: 'none' }} to={'/search/{"property_type":["Villa Plots"]}'} >
                                <div className="footer-item">
                                    Plots
                                </div>
                            </Link>
                            <Link style={{ textDecoration: 'none' }} to={'/search/{"zoning":["Residential"]}'} >
                                <div className="footer-item">
                                    Residential
                                </div>
                            </Link>
                            <Link style={{ textDecoration: 'none' }} to={'/search/{"zoning":["Commercial"]}'} >
                                <div className="footer-item">
                                    Commercial
                                </div>
                            </Link>
                            <Link style={{ textDecoration: 'none' }} to={'/search/'} >
                                <div className="footer-item">
                                    All Property Types
                                </div>
                            </Link>
                        </ShowHide>
                    </Col>
                    <Col md={4}>
                        <ShowHide stroke="rgba(255,255,255,0.4)" ignore={window.innerWidth >= 768}
                            title={<div className="capital-text footer-sub" >
                                Properties by status
                            </div>}
                        >

                            <Link style={{ textDecoration: 'none' }} to={'/search/{"status":["New"]}'} >
                                <div className="footer-item">
                                    New Properties
                                </div>
                            </Link>
                            <Link style={{ textDecoration: 'none' }} to={'/search/{"status":["Under construction"]}'} >
                                <div className="footer-item">
                                    Under Construction Properties
                                </div>
                            </Link>
                            <Link style={{ textDecoration: 'none' }} to={'/search/{"status":["Ready to move"]}'} >
                                <div className="footer-item">
                                    Ready to Move Properties
                                </div>
                            </Link>
                            <Link style={{ textDecoration: 'none' }} to={'/search/{"status":["Resale"]}'} >
                                <div className="footer-item">
                                    Resale Properties
                                </div>
                            </Link>
                            <Link style={{ textDecoration: 'none' }} to={'/search/'} >
                                <div className="footer-item">
                                    All Properties
                                </div>
                            </Link>
                        </ShowHide>


                    </Col>
                </Row>
                <Row >
                    <Col md={12}>
                        <div className="capital-text footer-top">
                            <hr style={{ marginBottom: '3em' }}></hr>

                            Resources and Links
                        </div>
                    </Col>
                    <Col md={4}>
                        <ShowHide stroke="rgba(255,255,255,0.4)" ignore={window.innerWidth >= 768}
                            title={<div className="capital-text footer-sub" >
                                Curated Collections
                            </div>}
                        >
                            {collection && collection.results.map(p => {
                                return (<Link style={{ textDecoration: 'none' }} to={'/collections/' + p.data.name} >
                                    <div className="footer-item">
                                        {p.data.name}
                                    </div>
                                </Link>)
                            })}

                        </ShowHide>
                    </Col>
                    <Col md={4}>
                        <ShowHide stroke="rgba(255,255,255,0.4)" ignore={window.innerWidth >= 768}
                            title={<div className="capital-text footer-sub" >
                                Quick Links
                            </div>}
                        >
                            <Link style={{ textDecoration: 'none' }} to={'/'} >
                                <div className="footer-item">
                                    Home
                                </div>
                            </Link>
                            <Link style={{ textDecoration: 'none' }} to={'/about'} >
                                <div className="footer-item">
                                    Our Team
                                </div>
                            </Link>
                            <Link style={{ textDecoration: 'none' }} to={'/careers'} >
                                <div className="footer-item">
                                    Careers
                                </div>
                            </Link>
                            <Link style={{ textDecoration: 'none' }} to={'/contact'} >
                                <div className="footer-item">
                                    Contact us
                                </div>
                            </Link>
                            <Link style={{ textDecoration: 'none' }} to={'/blogs'} >
                                <div className="footer-item">
                                    Blogs
                                </div>
                            </Link>

                            <Link style={{ textDecoration: 'none' }} to={'/awards&press'} >
                                <div className="footer-item">
                                    Awards and Press
                                </div>
                            </Link>
                            <Link style={{ textDecoration: 'none' }} to={'/accreditations&associations/'} >
                                <div className="footer-item">
                                    Accreditation & Associations
                                </div>
                            </Link>

                        </ShowHide>
                    </Col>
                    <Col md={4}>
                        <ShowHide stroke="rgba(255,255,255,0.4)" ignore={window.innerWidth >= 768}
                            title={<div className="capital-text footer-sub" >
                                Resources
                            </div>}
                        >

                            <Link style={{ textDecoration: 'none' }} to={'/guide'} >
                                <div className="footer-item">
                                    Home Buyer's Guide
                                </div>
                            </Link>
                            <Link style={{ textDecoration: 'none' }} to={'/faq'} >
                                <div className="footer-item">
                                    FAQ
                                </div>
                            </Link>
                            <Link style={{ textDecoration: 'none' }} to={'/pricing'} >
                                <div className="footer-item">
                                    Pricing
                                </div>
                            </Link>
                            <Link style={{ textDecoration: 'none' }} to={'/emi'} >
                                <div className="footer-item">
                                    EMI Calculator
                                </div>
                            </Link>
                            <Link style={{ textDecoration: 'none' }} to={'/vastu'} >
                                <div className="footer-item">
                                    Vastu Tips
                                </div>
                            </Link>
                            <Link style={{ textDecoration: 'none' }} to={'/nri'} >
                                <div className="footer-item">
                                    NRI Services
                                </div>
                            </Link>
                            <Link style={{ textDecoration: 'none' }} to={'/loan'} >
                                <div className="footer-item">
                                    Get Home Loan
                                </div>
                            </Link>
                        </ShowHide>
                    </Col>
                </Row>
                <hr style={{ marginTop: "5em" }}></hr>
                <Row className="brand-footer">

                    <Col style={{ marginTop: "2em" }} className="footer-item" md={12}>
                        <img style={{ display: "block" }} src="/Images/full_icon.webp" className="footer-img">
                        </img>
                    </Col>

                    <Col className="footer-item pt-4" style={{ textAlign: 'justify' }} md={8}>
                        Home Konnect™ is a boutique CRISIL Rated & RERA Certified Real estate advisory
                        with a refreshingly honest approach. At Home Konnect™, we provide end-to-end property
                        solutions for home buyers, owners, lessees and lessors. Our expert team of Real Estate
                        advisors is known for forging & nurturing long standing relationships built on trust,
                        transparency, ethics, professionalism & our industry expertise. We have helped more
                        than 1000+ happy customers connect to their dream homes selling 10 Lakh + sq.ft.
                        of residential space. Our team has some of the best professionals in the Real estate
                        industry who specialize in finding the right property for our clients thereby creating
                        long standing value for them.
                    </Col>
                    <Col style={{ marginTop: "2em" }} md={4}>
                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                            <img className="footer-cred footer-crisil" src="/Images/crisil.webp" />
                            <img className="footer-cred" src="/Images/top2.webp" />
                            <img className="footer-cred nar-icon" src="/Images/nar.webp" />
                        </div>
                        <div style={{ marginTop: '1em', textAlign: 'center' }} className='footer-item'>
                            RERA Agent Number: {data.rera_id}

                        </div>
                    </Col>
                </Row>

            </Container>
            <OfficeMap />
            <Container className='mt-4'>
                <center>
                    <a style={{color: 'white', backgroundImage: "linear-gradient(white, white)"}} className='footer-item capital-text link-stuff' target="_blank" href={data.google_maps_link}>View on Google Maps</a>
                </center>
            </Container>
            <Container style={{ paddingTop: '1em', paddingBottom: '7em' }}>

                <center>
                    <div className="capital-text" style={{ opacity: '0.7', marginTop: '2em' }}>Contact us</div>

                    <div className="bottom-logos" style={{ display: 'flex', justifyContent: 'center', marginTop: '1em', marginBottom: "2em" }}>

                        <div onClick={() => chat_invoke()}> {msg_logo_tiny}</div>
                        <div>
                            <a target="_blank" style={{ textDecoration: 'none' }} href={"tel://" + data.phone_number} >
                                {phone_icon_tiny}
                            </a>
                        </div>
                        <div>
                            <a target="_blank" style={{ textDecoration: 'none' }} href={"https://wa.me/" + data.whatsappbot + "?text=" + whatsapp_message} >
                                {whatsapp_icon_tiny}
                            </a>
                        </div>
                        <div>
                            <Link style={{ textDecoration: "none" }} to="/contact">
                                {enq_button_tiny}
                            </Link>
                        </div>
                    </div>
                </center>
                <center>
                    <div className="d-none d-md-block">
                        <Link to="/privacy" className="link-stuff capital-text" style={{ color: 'var(--accent)', marginRight: '1em' }}>
                            Privacy Policy
                        </Link>
                        <span style={{ opacity: '0.7' }}>|</span>
                        <Link to="/terms" className="link-stuff capital-text" style={{ color: 'var(--accent)', marginLeft: '1em', marginRight: '1em' }}>
                            Terms of Use
                        </Link>
                        <span style={{ opacity: '0.7' }}>|</span>
                        <Link to="/sitemap" className="link-stuff capital-text" style={{ color: 'var(--accent)', marginLeft: '1em' }}>
                            Sitemap
                        </Link>
                    </div>
                    <div className="d-block d-md-none">
                        <Link to="/privacy" className="link-stuff capital-text" style={{ color: 'var(--accent)' }}>
                            <div>
                                Privacy Policy
                            </div>
                        </Link>
                        <Link to="/terms" className="link-stuff capital-text" style={{ color: 'var(--accent)' }}>
                            <div>
                                Terms of Use
                            </div>
                        </Link>
                        <Link to="/sitemap" className="link-stuff capital-text" style={{ color: 'var(--accent)' }}>
                            <div>
                                Sitemap
                            </div>
                        </Link>
                    </div>

                    <div style={{ paddingTop: '0.5em' }} className="footer-item">&copy; HomeKonnect.com {year}. All rights reserved.</div>
                    <div className="footer-social" style={{ display: 'flex', width: 'max-content', marginTop: '10px' }}>
                        <div>
                            <a target="_blank" href={data.facebook_link}>
                                {facebook_icon_small_dark}
                            </a>
                        </div>
                        <div>
                            <a target="_blank" href={data.instagram_link}>
                                {insta_small_dark}
                            </a>
                        </div>

                        <div>
                            <a target="_blank" href={data.linkedin_link}>

                                {linkedin_icon_small_dark}
                            </a>
                        </div>
                        <div>
                            <a target="_blank" href={data.youtube_link}>

                                {utube_small_dark}
                            </a>
                        </div>
                        <div>
                            <a target="_blank" href={data.twitter_link}>

                                {twitter_icon_small_dark}
                            </a>
                        </div>
                        <div>
                            <a target="_blank" href={data.pinterest_link}>
                                {pinterest_icon_small_dark}
                            </a>
                        </div>
                    </div>

                </center>
            </Container>

        </Container >
    )
}
