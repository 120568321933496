import { Search, GetPropertyData } from "./ContentProvider";

class AutoCompleter {
    constructor() {
        this.contents = null;
        this.results = null;

        this.run_core = async (input, city) => await AutoCompleteCore(input, city);



        setInterval(async () => {
            if (this.contents) {

                var outs = await this.run_core(this.contents.input, this.contents.city);
                this.results = outs;
                this.contents = null;
            }
        }, 1000)


    };

    add(input, city) {
        this.contents = {
            input: input,
            city: city
        }
    }

    async run(input, city) {
        this.add(input, city);
        if (this.results) return this.results;
        var outs = await this.run_core(input, city);
        this.results = outs;
        return outs;
    }
}

const auto_driver = new AutoCompleter();

export async function AutoComplete(input, city) {
    return await AutoCompleteCore(input, city)
}




export async function AutoCompleteCore(input, city) {
    var cities = await window.cities;
    var builders = await window.builders;
    var property_type = await window.property_type;
    var amenities = await window.amenities;


    var search_terms = [];
    cities = cities.map(p => p.city_name)
    builders = builders.map(p => p.builder_name);
    property_type = property_type.map(p => p.property_type);
    amenities = amenities.map(p => p.amenity);


    search_terms = search_terms.concat(cities.map(p => {
        return {
            value: p,
            city: p
        }
    }))


    search_terms = search_terms.concat(builders.map(p => {
        return {
            value: p,
            builder: p
        }
    }))

    search_terms = search_terms.concat(property_type.map(p => {
        return {
            value: p,
            property_type: p
        }
    }))

    search_terms = search_terms.concat(amenities.map(p => {
        return {
            value: p,
            amenity: p
        }
    }))

 
    var cloud_data = (input != "" && input.length > 0) ? await Search({
        city: [city],
        text: input,
        fetchItem: ["properties.full_name", "properties.city", "properties.location", "properties.rera_number"],
        ignore: "city",
        page_size: 1000
    }) : [];
    var input_tokens = input.split(" ");

    var outs = search_terms.filter(p => {
        var idx = input_tokens.find(q => {

            return q.length > 2 && (p.value.toLowerCase().includes(q.toLowerCase()) || q.toLowerCase().includes(p.value.toLowerCase()));
        })
        if (idx) return true;
        return false;
    })


    var sel_cities = [];
    var sel_builder = [];
    var sel_property = [];
    var sel_amenity = [];
    for (var i = 0; i < outs.length; i++) {
        const current = outs[i];
        if (current.city) sel_cities.push(current.city);
        if (current.builder) sel_builder.push(current.builder);
        if (current.property_type) sel_property.push(current.property_type);
        if (current.amenity) sel_amenity.push(current.amenity)
    }

    if (sel_cities.length == 0) sel_cities = [city];

    // Property_type in city by with 

    var txt_cities = sel_cities;
    var txt_builder = sel_builder;
    var txt_property = sel_property;
    var txt_amenity = sel_amenity;

    if (txt_property.length == 0) {
        txt_property = ["Properties"]
    }


    var outputs = []
    for (var i = 0; i < txt_property.length; i++) {
        const i_property_type = txt_property[i];
        for (var j = 0; j < txt_cities.length; j++) {
            const i_city = txt_cities[j];
            var i_amenity = "";

            for (var k = 0; k < txt_amenity.length; k++) {
                i_amenity += ((k == 0) ? "" : ",") + txt_amenity[k]
            }

            if (txt_builder.length > 0) {
                for (var l = 0; l < txt_builder.length; l++) {
                    var i_builder = txt_builder[l];
                    const results = i_property_type + " in " + i_city + ((i_amenity != "") ? " with " : "") + i_amenity + " by " + i_builder;

                    var out = {
                        city: [i_city],
                        builder: [i_builder],
                        text: [results]
                    }

                    if (i_amenity != "") out = { ...out, amenity: [i_amenity] }
                    if (i_property_type != "Properties") out = { ...out, property_type: [i_property_type] }
                    outputs.push(out)
                }
            }
            else {
                const results = i_property_type + " in " + i_city + ((i_amenity != "") ? " with " : "") + i_amenity;

                var out = {
                    city: [i_city],
                    text: [results]
                }

                if (i_amenity != "") out = { ...out, amenity: [i_amenity] }
                if (i_property_type != "Properties") out = { ...out, property_type: [i_property_type] }
                outputs.push(out);
            }
        }
    }
    

    if (cloud_data && cloud_data.results) {
        var data = cloud_data.results.map(p => ({ full_name: p.data.full_name, city: [p.data.city.data ? p.data.city.data.city_name : ""], text: p.data.full_name + ", " + p.data.location }))
        if (data.length == 1) {
            const current = data[0]
            const cities = await window.cities;
            const city_id = cities.find(p => p.city_name == current.city[0]);
            if (city_id) {
                var similar = await GetPropertyData(city_id.id, current.full_name);
                similar = similar[0].data.similar_projects
                data[0].similar = similar
            }
        }

        return {
            city: sel_cities,
            builder: sel_builder,
            property_type: sel_property,
            amenity: sel_amenity,
            auto_complete: outputs,
            properties: data
        }
    }

    return {
        city: sel_cities,
        builder: sel_builder,
        property_type: sel_property,
        amenity: sel_amenity,
        auto_complete: outputs
    }

}