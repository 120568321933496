import React, { useState, useEffect } from 'react'
import { useData } from '../contexts/DataContext';
import { Dropdown } from 'react-bootstrap'


export default function CitiesDropDown(props) {
    const [allcities, setAllcities] = useState([]);
    const { city, setCity } = useData();

    const [current_city, setCurrentcity] = useState()
    useEffect(async () => {
        const cities = await window.cities;
        setAllcities(cities);
    }, [])


    const stroke = props.stroke? props.stroke : "white"
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <div onClick={(e) => { e.preventDefault(); onClick(e) }}
        >
            {children}
            <span className="city-drops-svg" style={{ float: 'right', marginLeft: '0.5em' }}>
                <svg style={{transform:'translateY(-20%)'}} width="15" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.13635 1.18457L8.42175 8.46997L15.7072 1.18457" stroke={stroke} stroke-linecap="round" strokeWidth="1.5" stroke-linejoin="round" />
                </svg>
            </span>
        </div>
    ));

    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
            const [value, setValue] = useState('');
            return (
                <div
                    ref={ref}
                    style={{ ...style, transform: 'translateX(-23px)' }}
                    className={className}
                    aria-labelledby={labeledBy}
                >
                    {allcities.map(p => {
                        return (
                            <div onClick={() => { setCity(p.city_name); setCurrentcity(p.city_name) }} style={{ textAlign: 'left', display: 'block' }} className="drop-item">
                                {p.city_name}
                            </div>
                        )
                    })}
                </div>
            );
        },
    );


    return (
        <Dropdown className="city-drops" style={{cursor: 'pointer'}}>
            <Dropdown.Toggle as={CustomToggle} >
                {city}
            </Dropdown.Toggle>
            <Dropdown.Menu className="corner-dropdown capital-text" as={CustomMenu}>
            </Dropdown.Menu>
        </Dropdown>
    )
}