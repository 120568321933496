import React, { useState, useRef, useEffect } from 'react'
import CitiesDropDown from './CitiesDropdown'
import { AutoComplete } from '../classes/SearchHelper'
import { useData } from '../contexts/DataContext';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import "../css/search.css";
import { GetPropertyData } from '../classes/ContentProvider';
import { featuredProjectList } from './FeaturedProjects';

const search_icon = (<svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.1725 21.9895L16.3917 17.2002L21.1725 21.9895ZM19.041 10.7995C19.041 13.2019 18.0867 15.506 16.3878 17.2049C14.689 18.9037 12.3849 19.8581 9.98244 19.8581C7.57995 19.8581 5.27585 18.9037 3.57703 17.2049C1.87821 15.506 0.923828 13.2019 0.923828 10.7995C0.923828 8.39696 1.87821 6.09287 3.57703 4.39405C5.27585 2.69523 7.57995 1.74084 9.98244 1.74084C12.3849 1.74084 14.689 2.69523 16.3878 4.39405C18.0867 6.09287 19.041 8.39696 19.041 10.7995V10.7995Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>
)



export default function SearchBarDesktop(props) {
    const { city } = useData();
    const [auto_complete, setAutoComplete] = useState();
    const [properties, setPropeties] = useState();
    const [parsed, setParsed] = useState();
    const [searchText, setSearchText] = useState("");
    const [sp, setsp] = useState("");
    const auto_complete_len = 4;
    const history = useHistory();
    const search_box = useRef(null);

    const [featured, setFeatured] = useState([])
    const print_message = "\u202ASearch by Location, Project, Developer or RERA ID...\u202C";
    const print_speed = 150;
    const backspace_speed = 30;

    const num_featured = 5;
    useEffect(() => {
        setTimeout(() => printLetterByLetter(print_message, print_speed), 2000);
    }, [])

    useEffect(async () => {
        try {

            if (props.text) {
                search_box.current.value = props.text;
                setSearchText(props.text);
            }
            handle_update({ target: search_box.current })
            const fp = await featuredProjectList(city);

            setFeatured(fp.results.slice(0, num_featured));
        }
        catch (err) {
        }
    }, [city, props.text])


    async function handle_update(e) {
        AutoComplete(e.target.value, city).then(outs => {
            setParsed(outs);
            setSearchText(e.target.value)
            setPropeties(outs.properties ? outs.properties.slice(0, auto_complete_len) : null)
            setAutoComplete(outs.auto_complete.slice(0, auto_complete_len));
        })

    }

    async function go_search(e) {
        setSearchText(e.text);
        search_box.current.value = e.text;
        var copy = JSON.parse(JSON.stringify(e));
        delete copy.text;
        const u = encodeURI(JSON.stringify(copy));
        history.push("/search/" + u);
    }

    function stepForward(timestamp, start, message, speed) {
        if (start === undefined)
            start = timestamp;
        const elapsed = timestamp - start;
        const i = Math.round(elapsed / speed);
        var temp = message.substring(0, i);
        setsp(temp);

        if (i < message.length && searchText == "") {
            setTimeout(() => window.requestAnimationFrame((timestamp) => {
                stepForward(timestamp, start, message, speed);
            }), speed)
        }
    }

    async function printLetterByLetter(message, speed) {

        window.requestAnimationFrame((timestamp) => {
            stepForward(timestamp, undefined, message, speed);
        });
    }


    async function text_search() {
        if (searchText.length == 0) return history.push("/search/");
        const u = encodeURI(JSON.stringify({
            city: [city],
            text: searchText,
        }));
        history.push("/search/" + u);
    }

    function AutoRender(props) {
        const [prev_props, setPrevProps] = useState({});
        const [data, setData] = useState()
        useEffect(async () => {
            if (prev_props == props) return;
            setPrevProps(props);
            setData(props)
        }, [props.properties, props.data])

        var extra = false;
        if (props.text != "") {
            if (!props.properties || props.properties.length == 0) {
                if (props.auto_complete) {
                    if (!props.auto_complete[0].builder || props.auto_complete[0].builder.length == 0) {
                        extra = true;
                    }
                }
            }
        }

        if (props.properties && props.properties.length == 1) {
            // Properties matched
            const similar_array = props.properties[0].similar.filter(p => (p.item && p.item.data && p.item.data.full_name));
            if (similar_array.length > 0)
                return (<>
                    <div style={{ paddingLeft: '1.7em', paddingTop: '0.1em', paddingBottom: '0.1em' }} className="auto-label capital-text">Similar Projects</div>
                    {props.properties[0].similar.filter(p=> (p.item.data && p.item.data.city && p.item.data.city.data.city_name)).map(p => {
                        return (
                            <Link style={{ textDecoration: "none", color: "black" }} to={"/property/" + (p.item.data.city && p.item.data.city.data.city_name) + "/" + p.item.data.full_name}>
                                <div className="bar-suggest">
                                    {p.item.data.full_name}, {p.item.data.location}
                                </div>
                            </Link>)
                    })}
                    {(props.featured && props.featured.length > 0) ? <>
                        <div style={{ paddingLeft: '1.7em', paddingTop: '0.1em', paddingBottom: '0.1em' }} className="auto-label capital-text">Featured Projects</div>
                        {props.featured.map(p => {
                            return (
                                <Link style={{ textDecoration: "none", color: "black" }} to={"/property/" + p.data.city.data.city_name + "/" + p.data.full_name}>
                                    <div className="bar-suggest">
                                        {p.data.full_name}, {p.data.location}
                                    </div>
                                </Link>)
                        })}
                    </> : ""}


                </>
                )
        }

        var found_builders = props.auto_complete && props.auto_complete.map(p=> p.builder).filter(q=> q !=null);

        if (found_builders && found_builders.length > 0) {
            // Matched builder
            return (<>
                <div style={{ paddingLeft: '1.7em', paddingTop: '0.1em', paddingBottom: '0.1em' }} className="auto-label capital-text">Builders</div>

                {found_builders.map(p => {
                    return (
                        <Link style={{ textDecoration: "none", color: "black" }} to={"/builders/" + p}>
                            <div className="bar-suggest">
                                {p}
                            </div>
                        </Link>)
                })}
            </>)
        }


        if (props.auto_complete && props.auto_complete[0].city) {
            // Match city
            const city = props.auto_complete[0].city;
            const new_props = JSON.stringify({ city: city, status: ['New'] });
            const under_props = JSON.stringify({ city: city, status: ['Under construction'] });
            const ready_props = JSON.stringify({ city: city, status: ['Ready to move'] });
            const resale_props = JSON.stringify({ city: city, status: ['Resale'] });

            return (<>
                {extra && props.text.length>1 && (<div className="bar-suggest">
                    <strong>Sorry, no matching properties found.</strong>
                </div>)}
                {(props.featured && props.featured.length > 0) ? <>
                    <div style={{ paddingLeft: '1.7em', paddingTop: '0.1em', paddingBottom: '0.1em' }} className="auto-label capital-text">Featured Projects in {city[0]}</div>
                    {props.featured.map(p => {
                        return (<Link style={{ textDecoration: "none", color: "black" }} to={"/property/" + p.data.city.data.city_name + "/" + p.data.full_name}>
                            <div className="bar-suggest">
                                {p.data.full_name}, {p.data.location}
                            </div>
                        </Link>)
                    })}
                    <div style={{ paddingLeft: '1.7em', paddingTop: '0.1em', paddingBottom: '0.1em' }} className="auto-label capital-text">Suggestions</div>
                    <Link style={{ textDecoration: "none", color: "black" }} to={"/search/" + new_props}>
                        <div className="bar-suggest">
                            New Properties in {city[0]}
                        </div>
                    </Link>
                    <Link style={{ textDecoration: "none", color: "black" }} to={"/search/" + under_props}>
                        <div className="bar-suggest">
                            Under Construction Properties in {city[0]}

                        </div>
                    </Link>
                    <Link style={{ textDecoration: "none", color: "black" }} to={"/search/" + ready_props}>
                        <div className="bar-suggest">
                            Ready to Move Properties in {city[0]}
                        </div>
                    </Link>

                    <Link style={{ textDecoration: "none", color: "black" }} to={"/collections/luxury"}>
                        <div className="bar-suggest">
                            Luxury Properties in {city[0]}
                        </div>
                    </Link>
                    <Link style={{ textDecoration: "none", color: "black" }} to={"/collections/Budget Friendly"}>
                        <div className="bar-suggest">
                            Budget friendly Properties in {city[0]}
                        </div>
                    </Link>
                    <Link style={{ textDecoration: "none", color: "black" }} to={"/search/" + resale_props}>
                        <div className="bar-suggest">
                            Resale Properties in {city[0]}
                        </div>
                    </Link>


                </> : ""
                }
            </>)
        }
        return (<>
            {/* HI */}
        </>)
    }

    const extra = (props.id) ? { id: props.id } : {};
    var margins = (props.center) ? { marginLeft: 'auto', marginRight: 'auto' } : {}


    const placeholder_visible = (searchText && searchText.length>0)? "false" : "true";
    return (<>
        <div style={{ display: "flex", cursor: 'pointer' }} className="d-block d-md-none">

            <div style={{ ...margins, width: '200px', color: props.stroke ? props.stroke : "black", marginBottom: '1em' }} className="capital-text">
                <CitiesDropDown stroke={props.stroke ? props.stroke : "black"} />
            </div>

        </div>
        <div className="search-bar">
            <div style={{ cursor: 'pointer' }} className="search-location-wrap">
                <div style={{ zIndex: '4' }} className="capital-text search-location">
                    <CitiesDropDown stroke="black" />
                </div>
            </div>
            <div style={{ position: 'relative' }} className="search-box">
                <input ref={search_box} data-status="false"  onChange={handle_update} className="search-field gen-text soorkie-search" type="text"  {...extra}>
                </input>
                <div className="search-placeholder gen-text" data-status={placeholder_visible}>{sp}</div>
                <div className="auto-complete gen-text">
                    {
                        (searchText.length > 0) ? (<div className="bar-suggest" onClick={text_search}  >
                            Searching for {searchText}
                        </div>) : ""
                    }
                    {(properties && properties.length > 0) ? <div style={{ paddingLeft: '1.7em', paddingTop: '0.1em', paddingBottom: '0.1em' }} className="capital-text auto-label">Properties</div> : ""}
                    {properties && properties.map(p => {
                        return (
                            <Link style={{ textDecoration: "none", color: "black" }} to={"/property/" + p.city[0] + "/" + p.full_name}>
                                <div className="bar-suggest" style={{ textTransform: "capitalize" }}>
                                    {p.text}
                                </div>
                            </Link>)
                    })}
                    <AutoRender text={searchText} featured={featured} auto_complete={auto_complete} properties={properties} />
                   
                </div>
            </div>
            <div className="search-btn">
                <div onClick={text_search} style={{ margin: 'auto', padding: '0.3em 1.5em' }}>
                    {search_icon}
                </div>
            </div>
        </div>
    </>
    )
}
