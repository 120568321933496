import React, { useState } from 'react'
import { useAuth } from "../contexts/AuthContext";
import { Link } from 'react-router-dom';
import "../css/user_corner.css";
import { isMobile } from 'react-device-detect';
import { Dropdown } from 'react-bootstrap';

export const profile_icon = (<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 1C5.477 1 1 5.477 1 11C1 16.523 5.477 21 11 21C16.523 21 21 16.523 21 11C21 5.477 16.523 1 11 1Z" stroke="white" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M3.271 17.346C3.271 17.346 5.5 14.5 11 14.5C16.5 14.5 18.73 17.346 18.73 17.346" stroke="white" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M11 11C11.7956 11 12.5587 10.6839 13.1213 10.1213C13.6839 9.55871 14 8.79565 14 8C14 7.20435 13.6839 6.44129 13.1213 5.87868C12.5587 5.31607 11.7956 5 11 5C10.2044 5 9.44129 5.31607 8.87868 5.87868C8.31607 6.44129 8 7.20435 8 8C8 8.79565 8.31607 9.55871 8.87868 10.1213C9.44129 10.6839 10.2044 11 11 11V11Z" stroke="white" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
</svg>);


const logout_icon = (<svg width="22" height="22" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_27:48)">
        <path d="M10.24 0.52179C13.385 0.52179 16.297 1.91679 18.228 4.26579C18.283 4.33237 18.3241 4.40935 18.3487 4.49212C18.3734 4.5749 18.3811 4.66179 18.3715 4.74762C18.3619 4.83346 18.3351 4.91648 18.2928 4.99176C18.2504 5.06703 18.1934 5.13302 18.125 5.18579C17.9858 5.29489 17.8096 5.34547 17.6337 5.3268C17.4579 5.30813 17.2962 5.22168 17.183 5.08579C16.3386 4.06301 15.2778 3.24042 14.0769 2.67736C12.8761 2.1143 11.5653 1.82481 10.239 1.82979C5.324 1.82979 1.339 5.72179 1.339 10.5218C1.339 15.3218 5.324 19.2138 10.239 19.2138C13.014 19.2138 15.577 17.9658 17.255 15.8708C17.3663 15.7335 17.5266 15.6449 17.7021 15.6238C17.8776 15.6027 18.0544 15.6508 18.195 15.7578C18.264 15.8097 18.3218 15.8749 18.3651 15.9495C18.4084 16.0242 18.4362 16.1068 18.4469 16.1924C18.4577 16.2781 18.4511 16.365 18.4275 16.448C18.404 16.531 18.364 16.6085 18.31 16.6758C16.382 19.0858 13.431 20.5218 10.24 20.5218C4.583 20.5218 0 16.0448 0 10.5218C0 4.99879 4.584 0.52179 10.24 0.52179ZM17.098 7.68179L19.804 10.3888C20.066 10.6498 20.071 11.0688 19.816 11.3248L17.172 13.9678C17.0465 14.0906 16.8775 14.1585 16.7019 14.1566C16.5264 14.1547 16.3588 14.0832 16.236 13.9578C16.1737 13.897 16.124 13.8246 16.0897 13.7446C16.0554 13.6646 16.0372 13.5786 16.0362 13.4916C16.0352 13.4045 16.0513 13.3182 16.0837 13.2374C16.1161 13.1566 16.1641 13.083 16.225 13.0208L17.772 11.4738H7.462C7.37506 11.4748 7.28877 11.4588 7.20805 11.4265C7.12732 11.3942 7.05375 11.3463 6.99154 11.2856C6.92932 11.2248 6.87968 11.1524 6.84544 11.0725C6.8112 10.9926 6.79304 10.9067 6.792 10.8198C6.792 10.4578 7.092 10.1648 7.462 10.1648H17.731L16.173 8.60679C16.0474 8.48411 15.9758 8.31659 15.9737 8.14107C15.9716 7.96554 16.0394 7.79638 16.162 7.67079C16.2876 7.54815 16.4568 7.48042 16.6323 7.48248C16.8078 7.48454 16.9753 7.55623 17.098 7.68179Z" fill="white" />
    </g>
    <defs>
        <clipPath id="clip0_27:48">
            <rect width="20" height="20" fill="white" transform="translate(0 0.52179)" />
        </clipPath>
    </defs>
</svg>);

export const heart_icon = (<svg width="22" height="22" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.3793 4.12242L10.4399 3.15677C8.23473 0.890033 4.19129 1.67225 2.73167 4.52205C2.04641 5.86243 1.8918 7.79767 3.14309 10.2675C4.34852 12.6456 6.85634 15.4941 11.3793 18.5968C15.9023 15.4941 18.4088 12.6456 19.6156 10.2675C20.8668 7.79636 20.7136 5.86243 20.027 4.52205C18.5674 1.67225 14.5239 0.888723 12.3188 3.15546L11.3793 4.12242ZM11.3793 20.1756C-8.71074 6.90015 5.19363 -3.46131 11.1487 2.01947C11.2273 2.09153 11.3046 2.16622 11.3793 2.24352C11.4533 2.16629 11.5302 2.09198 11.6099 2.02078C17.5637 -3.46393 31.4694 6.89884 11.3793 20.1756Z" fill="white" />
</svg>)

const right_arrow = (<svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9785 0.692785C11.685 0.399327 11.2102 0.396215 10.9129 0.685801V0.685801C10.6105 0.980437 10.6069 1.46536 10.9049 1.76446L16.1185 6.9966H1.57906C1.15975 6.9966 0.819824 7.33652 0.819824 7.75584V7.75584C0.819824 8.17515 1.15975 8.51507 1.57906 8.51507H16.1185L10.888 13.7407C10.5984 14.03 10.5986 14.4994 10.8883 14.7885V14.7885C11.1981 15.0977 11.6999 15.0975 12.0095 14.7879L18.4416 8.35581C18.7729 8.02446 18.7729 7.48722 18.4416 7.15586L11.9785 0.692785Z" fill="white" />
</svg>)

export function ProfileIcon(props) {
    const { currentUser } = useAuth();
    const r = (Math.random() + 1).toString(36).substring(7);
    return (<svg width="22" height="22" stroke={currentUser ? "url(#" + r + ")" : "white"} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 1C5.477 1 1 5.477 1 11C1 16.523 5.477 21 11 21C16.523 21 21 16.523 21 11C21 5.477 16.523 1 11 1Z" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M3.271 17.346C3.271 17.346 5.5 14.5 11 14.5C16.5 14.5 18.73 17.346 18.73 17.346" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M11 11C11.7956 11 12.5587 10.6839 13.1213 10.1213C13.6839 9.55871 14 8.79565 14 8C14 7.20435 13.6839 6.44129 13.1213 5.87868C12.5587 5.31607 11.7956 5 11 5C10.2044 5 9.44129 5.31607 8.87868 5.87868C8.31607 6.44129 8 7.20435 8 8C8 8.79565 8.31607 9.55871 8.87868 10.1213C9.44129 10.6839 10.2044 11 11 11V11Z" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
        <defs>
            <linearGradient id={r}>
            {/* rgb(207, 211, 0), rgb(221, 144, 0) */}
                <stop offset="0%" stop-color="rgb(207, 211, 0)" />
                <stop offset="150%" stop-color="rgb(207, 144, 0)" />
            </linearGradient>
        </defs>
    </svg>);
}

function LinkOrDiv(props) {
    return (props.active ? (<Link {...props}>{props.children}</Link>) : <div {...props}>{props.children}</div>);
}

function clean_name(name) {
    if (name.length > 15) {
        name = name.split(" ")[0];

    }
    if (name.length > 15) {
        name = name.substr(0, 15) + "...";
    }
    return (name);
}


function ProfileDropDown() {
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <div id="profile_drops" style={{ marginTop: 'auto', marginBottom: 'auto' }} onClick={(e) => { e.preventDefault(); onClick(e) }}
        >
            {children}
        </div>
    ));

    // forwardRef again here!
    // Dropdown needs access to the DOM of the Menu to measure it
    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {

            return (
                <div
                    ref={ref}
                    style={{ ...style, transform: 'translateX(-85%)' }}
                    className={className}
                    aria-labelledby={labeledBy}
                >

                    <Link to="/gold" className="drop-item gold-text">
                        HK Club gold
                    </Link>
                    <Link to="/profile" className="drop-item">
                        My Profile
                    </Link>
                    {/* <Link to="/shortlists" className="drop-item">
                        {heart_icon} Shortlists
                    </Link> */}
                    <Link to="/logout" className="drop-item">
                        {/* {logout_icon} Logout */}
                        Logout
                    </Link>
                </div>
            );
        },
    );

    return (
        <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                <div style={{ cursor: 'pointer' }}>
                    {/* {profile_icon} */}
                    <ProfileIcon />
                </div>
            </Dropdown.Toggle>

            <Dropdown.Menu className="corner-dropdown capital-text" as={CustomMenu}>

            </Dropdown.Menu>
        </Dropdown>
    )
}
export default function UserCorner() {
    const { currentUser, logout } = useAuth();

    const [drop, setDrop] = useState("corner-dropdown capital-text");
    return (<div title="Profile" style={{ marginTop: 'auto', marginBottom: 'auto' }}>


        {/* <div style={{ marginTop: 'auto', marginBottom: 'auto', marginRight: '1em'}} className="my-beautiful-name capital-text">
            {(currentUser.displayName) ? clean_name(currentUser.displayName) : (currentUser.email)? clean_name(currentUser.email) : clean_name(currentUser.phoneNumber)}
        </div> */}
        <ProfileDropDown />

        {/* </LinkOrDiv> */}
    </div>
    )
}
