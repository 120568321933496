import React, { useRef, useState, useEffect } from "react"
import Header from '../components/Header'
import Footer from '../components/Footer';
import { TransparentButton } from "../components/Button";
import config from 'react-reveal/globals';
import { useAuth } from "../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom"
import isEmail from 'validator/lib/isEmail';
import "../css/login.css";
import { Col, Row, Form } from 'react-bootstrap';
import Validate from "../classes/validation";
import GoogleSingin from "../components/GoogleSingin";
import PhoneSignin from "./PhoneSignin";

const left_arrow = (<svg width="28" height="28" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.6563 18.2663L0.874023 1.48401M0.874023 18.2663L17.6563 1.48401L0.874023 18.2663Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>
)

export default function RegisterComponent(props) {
    const nameRef = useRef();
    const emailRef = useRef();
    const passwordRef = useRef();
    const confPasswordRef = useRef();

    const { signupEmailPassword, currentUser, loginGoogle, redirectedResult } = useAuth();
    const [error, setError] = useState("");
    const [name_err, setNameErr] = useState("");
    const [mail_err, setMailErr] = useState("");
    const [pass_err, setPassErr] = useState("");
    const [conf_err, setConfErr] = useState("");

    const [name_touched, setNameTouched] = useState(false)
    const [mail_touched, setMailTouched] = useState(false);
    const [pass_touched, setPassTouched] = useState(false);
    const [conf_touched, setConfTouched] = useState(false);

    const [loading, setLoading] = useState(false);
    const history = useHistory();


    useEffect(() => {
        window.scrollTo(0, 0);
        if (currentUser) {
            redirectedResult().then((res) => {
                history.push("/profile");
            });
        }
    }, []);

    function validate_form(e) {

        const name = nameRef.current && nameRef.current.value;
        const email = emailRef.current && emailRef.current.value;
        const password = passwordRef.current && passwordRef.current.value;
        const confirmPassword = confPasswordRef.current && confPasswordRef.current.value;

        setError("");
        setNameErr("");
        setMailErr("");
        setPassErr("");
        setConfErr("");

        var valid, msg;

        var full_error = false;
        if(name) setNameTouched(true);
        [valid, msg] = Validate(name, 8, 256, /[^0-9a-zA-Z ]/, "Name", true);
        if (!valid) {
            setNameErr(msg)
            setError(msg);
            full_error = true
        }

        if(email) setMailTouched(true);
        [valid, msg] = Validate(email, 8, 256, /[\s\S]/, "Email", false);
        if (!valid) {
            setMailErr(msg)
            setError(msg);
            full_error = true
        }

        if (!isEmail(email)) {
            full_error = true;
            setMailErr("Email is not valid")
            setError("Email is not valid");
        }

        if(password) setPassTouched(true);
        [valid, msg] = Validate(password, 8, 256, /[\s\S]/, "Password", false);
        if (!valid) {
            setError(msg);
            setPassErr(msg)
            full_error = true
        }


        if(confirmPassword) setConfTouched(true);
        [valid, msg] = Validate(confirmPassword, 8, 256, /[\s\S]/, "Confirm Password", false);
        if (!valid) {
            setError(msg);
            setConfErr(msg)
            full_error = true
        }
        if (!(confirmPassword === password)) {
            setError("Password and Password Confirmation must be equal");
            setConfErr( "Password and Password Confirmation must be equal")

            full_error = true
        }

        return full_error;
    }

    async function handleSubmit(e) {
        setNameTouched(true);
        setMailTouched(true)
        setPassTouched(true)
        setConfTouched(true)
        if (loading) return;
        const name = nameRef.current && nameRef.current.value;
        const email = emailRef.current && emailRef.current.value;
        const password = passwordRef.current && passwordRef.current.value;

        const err = validate_form();
        if (err) return;

        try {
            setError("");
            setLoading(true)
            const result = await signupEmailPassword(email, password);


            history.push("/profile?firstLogin=true&name=" + name)
        } catch (err) {
            var readable_err = err.toString().replace("FirebaseError: Firebase: ", "").replace("(auth/email-already-in-use)", "Please login to continue");
            setError("Failed to signup: " + readable_err);
        }

        setLoading(false)
    }

    async function google_signin() {
        try {
            setError("");
            setLoading(true);
            await loginGoogle();

        }
        catch (err) {
            var readable_err = err.toString().replace("FirebaseError: Firebase: ", "").replace("(auth/email-already-in-use)", "Please login to continue");
            setError("Failed to signup: " + readable_err);
        }

        setLoading(false)
    }

    const switcher = (props.popup) ? (<span style={{ color: "var(--accent)" }} className="capital-text link-stuff" onClick={props.handle}>Login</span>) : (<Link style={{ color: "var(--accent)" }} className="capital-text link-stuff" to="/login">Login</Link>);

    const button_opacity = (loading) ? "0.5" : "1";

    var ch = new BroadcastChannel('phone-auth');
    ch.addEventListener('message', function (e) {
        if (e.data == "Auth OTP success") history.push("/profile");
    });

    var extra = (window.innerWidth < 468) ? { width: '100%' } : {};

    return (
        <div className="login-card" style={{ display: "flex", flexDirection: 'column' }}>
            <div className="return-arrow">
                {(props.done) ? (<div style={{ cursor: 'pointer', marginTop: 'auto', marginBottom: 'auto' }} onClick={() => props.done()}>
                    {left_arrow}
                </div>) : ""}
            </div>
            <Row>

                <Col xs={12}>
                    <center>
                        <h1>Register</h1>

                    </center>
                </Col>
                {/* <Col style={{ display: 'flex' }} xs={1}>
                    {(props.done) ? (<div style={{ cursor: 'pointer', marginTop: 'auto', marginBottom: 'auto' }} onClick={() => props.done()}>
                        {left_arrow}
                    </div>) : ""}
                </Col> */}
            </Row>
            <center>
                <p className="capital-text" style={{ color: "var(--accent", marginBottom: '2em' }}>
                    Create a new Homekonnect Club Gold Membership
                </p>

            </center>
            <Row style={{ marginTop: '1em', marginBottom: '1em' }}>
                <Col xl={5}>
                    <Row style={{ marginTop: '1em' }}>
                        <Col xs={6} xl={12}>
                            <GoogleSingin value="Google" onClick={google_signin} />
                        </Col>
                        <Col xs={6} xl={12}>
                            <PhoneSignin value="Phone" />
                        </Col>
                    </Row>
                </Col>
                <Col xl={7}>
                    <div style={{}}>
                        <Form onSubmit={handleSubmit} style={{}}>
                            <Form.Group id="name">
                                <Form.Label >Name</Form.Label>
                                <Form.Control onChange={validate_form} placeholder="Example: Jane Doe" className="gen-text" type="text" ref={nameRef} required />
                                <div className="error-box">
                                    {name_touched && name_err}
                                </div>
                            </Form.Group>
                            <Form.Group id="email">
                                <Form.Label >Email Address</Form.Label>
                                <Form.Control onChange={validate_form} placeholder="Example: janedoe@homekonnect.com" className="gen-text" type="email" ref={emailRef} required />
                                <div className="error-box">
                                    {mail_touched && mail_err}
                                </div>
                            </Form.Group>
                            <Form.Group id="password">
                                <Form.Label >Password</Form.Label>
                                <Form.Control onChange={validate_form} type="password" ref={passwordRef} required />
                                <div className="error-box">
                                    {pass_touched && pass_err}
                                </div>
                            </Form.Group>
                            <Form.Group id="confirm_password" style={{ marginBottom: 0 }}>
                                <Form.Label >Confirm Password</Form.Label>
                                <Form.Control onChange={validate_form} type="password" ref={confPasswordRef} required />
                                <div className="error-box">
                                    {conf_touched && conf_err}
                                </div>
                            </Form.Group>
                            <br></br>
                            <div style={{ opacity: button_opacity, ...extra }} onClick={handleSubmit} className="trans-btn-accent">
                                <TransparentButton value="Register" style={{ borderStyle: "1px solid rgba(255, 255, 255, 0.5)", borderColor: "rgba(255, 255, 255, 0.7)", backgroundColor: "transparent", buttonColor: "btn-white", ...extra }} />
                            </div>
                            <div className="error-box">
                                {!(name_err || mail_err || pass_err || conf_err) && error}
                            </div>

                        </Form>
                    </div>
                </Col>
            </Row>
            <hr></hr>
            <center>
                <div className="gen-text">
                    By proceeding, you agree with our <Link style={{ color: "var(--accent)" }} className="capital-text link-stuff" target="_blank" to="/privacy">Privacy Policy</Link> and <Link style={{ color: "var(--accent)", marginLeft: '5px' }} className="capital-text link-stuff" target="_blank" to="/terms">Terms of use.</Link>
                </div>
                <p className="gen-text">
                    {switcher}
                </p>
            </center>
        </div>
    )
}
