import React, { useRef, useState, useEffect, useCallback } from "react"
import Header from '../components/Header'
import Footer from '../components/Footer';
import { Button, TransparentButton } from "../components/Button";
import config from 'react-reveal/globals';
import { useAuth } from "../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom"
import Validate from "../classes/validation";
import isEmail from 'validator/lib/isEmail';

import "../css/login.css";
import { Col, Row, Form } from 'react-bootstrap';
import GoogleSingin from "../components/GoogleSingin";
import PhoneSignin from "../components/PhoneSignin";

config({ ssrFadeout: true });

const left_arrow = (<svg width="28" height="28" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.6563 18.2663L0.874023 1.48401M0.874023 18.2663L17.6563 1.48401L0.874023 18.2663Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>
)


export default function LoginComponent(props) {
    const emailRef = useRef();
    const passwordRef = useRef();

    const { loginEmailPassword, sendVerificationEmail, currentUser, loginGoogle, redirectedResult } = useAuth();
    const [error, setError] = useState("");
    const [mail_err, setMailErr] = useState("");
    const [pass_err, setPassErr] = useState("");
    const [mail_touched, setMailTouched] = useState(false);
    const [pass_touched, setPassTouched] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    async function google_signin() {
        const res = await loginGoogle();
    }

    var ch = new BroadcastChannel('phone-auth');
    ch.addEventListener('message', function (e) {
        if (e.data == "Auth OTP success") {
            if (props.done) props.done()
            else history.push("/")
        }
    });

    function validate_form() {
        const email = emailRef.current && emailRef.current.value;
        const password = passwordRef.current && passwordRef.current.value;

        setError("");
        setMailErr("");
        setPassErr("");
        var full_error = false;

        if(email) setMailTouched(true);
        if(password) setPassTouched(true);
        var [valid, msg] = Validate(email, 8, 256, /[\s\S]/, "Email", false);
        if (!valid) {
            setError(msg);
            full_error =  true;
            // setMailErr(email? msg : "")
            setMailErr(msg)

        }

        if (!isEmail(email)) {
            setMailErr("Email is not valid")
            // setMailErr(email? "Email is not valid" : "")
            setError("Email is not valid");
            full_error = true;
        }

        
        [valid, msg] = Validate(password, 8, 256, /[\s\S]/, "Password", false);
        if (!valid) {
            // setPassErr(password?msg:"");
            setPassErr(msg)
            setError(msg);
            full_error =  true
        }

        return full_error;
    }

    async function handleSubmit(e) {
        const err = validate_form();
        setMailTouched(true);
        setPassTouched(true);
        if (err) return;

        try {
            setError("");
            setLoading(true)
            await loginEmailPassword(emailRef.current.value, passwordRef.current.value)
            if (props.done) props.done()
            else history.push("/")
        } catch (err) {
            var readable_err = err.toString().replace("FirebaseError: Firebase: ", "").replace("(auth/user-not-found)", "Please register a new account to continue");
            setError("Failed to signup: " + readable_err);
        }

        setLoading(false)
    }

    async function verify_captcha(e) {
    }

    const switcher = (props.popup) ? (<span style={{ color: "var(--accent)" }} className="capital-text link-stuff" onClick={props.handle}>Register</span>) : (<Link style={{ color: "var(--accent)" }} className="capital-text link-stuff" to="/register">Register</Link>);

    const button_opacity = (loading) ? "0.5" : "1";

    var extra = (window.innerWidth < 468) ? { width: '100%' } : {};
    return (
        <div className="login-card" style={{ display: "flex", flexDirection: 'column' }}>
            <div className="return-arrow">
                {(props.done) ? (<div style={{ cursor: 'pointer', marginTop: 'auto', marginBottom: 'auto' }} onClick={() => props.done()}>
                    {left_arrow}
                </div>) : ""}
            </div>


            <Row>

                <Col xs={12}>
                    <center>
                        <h1>Login</h1>

                    </center>
                </Col>
            </Row>
            <center>
                <p className="capital-text d-none md-block" style={{ color: "var(--accent", marginBottom: '2em' }}>
                    Login with your existing homekonnect club gold membership
                </p>
                <p className="capital-text d-block md-none" style={{ color: "var(--accent", marginBottom: '2em' }}>
                    Login to HK club gold
                </p>

            </center>
            <Row style={{ marginTop: '0em', marginBottom: '1em' }}>
                <Col xl={5}>
                    <Row style={{ marginTop: '0.5em' }}>
                        <Col xs={6} xl={12}>
                            <GoogleSingin value="Google" onClick={google_signin} />
                        </Col>
                        <Col xs={6} xl={12}>
                            <PhoneSignin value="Phone" />
                        </Col>
                    </Row>
                </Col>
                <Col xl={7}>
                    <div style={{}}>
                        <Form onChange={validate_form} onSubmit={handleSubmit} style={{}}>
                            <Form.Group id="email">
                                <Form.Label >Email Address</Form.Label>
                                <Form.Control placeholder="" className="gen-text" type="email" ref={emailRef} required />
                                <div className="error-box">
                                    {mail_touched && mail_err}
                                </div>
                            </Form.Group>
                            <Form.Group id="password">
                                <Form.Label >Password</Form.Label>
                                <Form.Control type="password" ref={passwordRef} required />
                                <div className="error-box">
                                    {pass_touched && pass_err}
                                </div>
                            </Form.Group>
                            <br></br>
                            <div style={{ opacity: button_opacity, ...extra }} onClick={handleSubmit} className="trans-btn-accent">
                                <TransparentButton value="Login" style={{ borderStyle: "1px solid rgba(255, 255, 255, 0.5)", borderColor: "rgba(255, 255, 255, 0.7)", backgroundColor: "transparent", buttonColor: "btn-white", ...extra }} />
                            </div>

                            <div className="error-box">
                                {!mail_err && !pass_err && error}
                            </div>
                        </Form>
                    </div>
                </Col>
            </Row>
            <hr className="d-none d-md-block"></hr>
            <center>
                <div className="gen-text">
                    By proceeding, you agree with our <Link style={{ color: "var(--accent)" }} className="capital-text link-stuff" target="_blank" to="/privacy">Privacy Policy</Link> and <Link style={{ color: "var(--accent)", marginLeft: "5px" }} className="capital-text link-stuff" target="_blank" to="/terms">Terms of use.</Link>
                </div>
                <p className="gen-text"><Link style={{ color: "var(--accent)", marginRight: '0.5em' }} className="capital-text link-stuff" to="/forgot_password">Forgot password?</Link>
                    {switcher}
                </p>
            </center>
        </div>

    )
}
