function clean(inp){
    if(!inp.includes(".")) return inp;
    var inps = inp.split(".");
    if(inps[0].length > 3) return inps[0];
    else if(inps[0].length > 1) return inps[0] + "." + inps[1].substr(0, 1);
    else return inps[0] + "." + inps[1].substr(0, 1);
}

export default function PriceConversion(price){
    var rupee = parseFloat(price);
    if(rupee>=10000000) {
        return clean((rupee/10000000).toString()) + " Cr"
    }
    if(rupee>=100000){
        return clean((rupee/100000).toString()) + " L"
    }
    else{
        return (Math.round(rupee)).toString()
    }
}