import ScrollIndicator from "./ScrollIndicator";

const phone_stuff = (
    <svg width="20" height="20" viewBox="0 0 46 81" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="phone-arrow-one" d="M1.82959 1.80597L30.8333 30.8096L1.82959 59.8132" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
        <path className="phone-arrow-two" d="M15.73 1.80597L44.7336 30.8096L15.73 59.8132" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)

export function NextArrow(props) {
    const { className, style, onClick } = props;
    return (<>
        <div
            className={className + " d-none d-md-block"}
            style={{ ...style }}
            onClick={onClick}
        >
            <div style={{ transform: "translateX(20px) translateY(-50px)" }}>
                <ScrollIndicator /></div>
        </div>
        <div className={className + "d-block d-md-none"}
            style={{ ...style }}
            onClick={onClick}>
            <div style={props.arrow_style} className="phone-next-arrow">
                <div style={{ transform: 'translateY(-3px) translateX(-5px)' }}>{phone_stuff}</div>
            </div>
        </div>
    </>
    );
}

export function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (<>
        <div
            className={className + " d-none d-md-block"}
            style={{ ...style }}
            onClick={onClick}
        >
            <div style={{ transform: "translateX(-20px) translateY(-50px) rotate(180deg)" }}>
                <ScrollIndicator />
            </div>
        </div>
        <div className={className + "d-block d-md-none"}
            style={{ ...style }}
            onClick={onClick}>
            <div style={props.arrow_style} className="phone-prev-arrow">
                <div style={{ transform: "rotate(180deg) translateY(3px) translateX(-5px)" }}>{phone_stuff}</div>
            </div>
        </div>
    </>
    );
}