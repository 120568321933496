import React, { useState, useEffect } from 'react'
import { Search } from '../classes/ContentProvider';
import { useData } from '../contexts/DataContext';
import PreviewGrids from './PreviewGrids';
import { Link } from 'react-router-dom';

export function featuredProjectList(city_name) {
    return Search({ city: [city_name], featured: true })
}


export default function FeaturedProjects(props) {
    const { city } = useData()
    const [data, setData] = useState([]);
    useEffect(async () => {
        var result = await Search({ city: [props.city], featured: true });

        if (result.results) {
            result = result.results.filter(p => {
                return (p.data.full_name != props.full_name)
            })

            setData(result);
        }
        else {
            setData([]);
        }
    }, [props])

    if (data.length < 1) return (<></>)

    return (<div>

        <div> <PreviewGrids identity="Featured" same_tab={true} data={data.map(p => ({ ...p.data, id: p.id }))} /></div>
        <center>
            <div style={{marginTop:'50px'}}>
                <Link style={{color: 'var(--accent)'}} className="link-stuff capital-text" to={"/property/" + city + "/featured"}>
                    View all
                </Link>
            </div>
        </center>
    </div>)
}

export function SimilarProjects(props) {

    const [data, setData] = useState([]);
    useEffect(async () => {
        setData(props.data.map(p => p.item));

    }, [props])

    if (data.length < 1) return (<></>)
    return (<div style={{ marginTop: '4em', marginBottom: '5em' }}>
        <h5>
            Similar projects in {props.city}
        </h5>
        <div> <PreviewGrids same_tab={true} data={data.map(p => ({ ...p.data, id: p.id }))} /></div>
    </div>)
}
