import React, { useState, useEffect } from 'react'
import "../css/popup.css"
import LoginComponent from './LoginComponent'
import RegisterComponent from './RegisterComponent'



export function LoginPopup(props) {
    if (props.active)
        return (
            <div className="popup-wrap">
                <LoginComponent done={props.done} popup={true} handle={props.handle} />
            </div>
        )
    else return (<></>)
}


export function RegisterPopup(props) {
    if (props.active)
        return (
            <div className="popup-wrap">
                <RegisterComponent done={props.done} popup={true} handle={props.handle} />
            </div>
        )
    else return (<></>)
}


export default function AuthComponent(props) {
    const [login, setLogin] = useState(true);

    useEffect(() => {
      if(props.way == "register"){
          setLogin(false)
      }
    }, [props.way]);
    
    if (props.active)
        return (
            <>
                
                <LoginPopup done={() => props.done()} active={login} handle={() => setLogin(false)} />
                <RegisterPopup done={() => props.done()} active={!login} handle={() => setLogin(true)} />
            </>
        )
    else {
        return (<></>)
    }
}


