
import Prismic from '@prismicio/client'
import { fallback_city } from '../contexts/DataContext';
import StorageBucket from './StorageBucket';

const apiEndpoint = 'https://homekonnectcms.prismic.io/api/v2'

const PrismicClient = Prismic.client(apiEndpoint);

class ThroughCache {
    constructor(target, ttl) {
        var time = (ttl) ? ttl : 4;
        this.storage = new StorageBucket(time * 60 * 60 * 1000);
        this.target = target;
    }

    call = async (query) => {
        const data = this.storage.load(JSON.stringify(query));
        if (data) {
            this.target(query).then(res => {
                this.storage.store(JSON.stringify(query), (res));
            }
            )
            return data
        }
        else {
            const out = await this.target(query);
            this.storage.store(JSON.stringify(query), out);
            return out
        }

    }
}

function rank_props(database, tokens) {
    database = [...new Set(database)];
    var outputs = database.map(p => {
        const out = tokens.map((q) => {
            if (q.length < 0) return false;
            const fullname = p.data.full_name;
            const data =" " + Object.entries(p.data).map(r => r[1]).filter(r => typeof(r) == 'string' ).join(" ");
            return data.toLowerCase().includes(q.toLowerCase())? ((fullname.substr(0, q.length).toLowerCase() == q.toLowerCase())? 30 : (data.toLowerCase().includes(" " + q.toLowerCase())? 10 : 1)) : 0;
        }
        );

        return {
            data: p,
            score: out.reduce((f, s) => (f + s), 0)
        }
    });
    outputs = outputs.filter(p => p.score != 0);
    outputs = outputs.sort((f, s) => (f.score > s.score) ? -1 : 1);
    
    return outputs.map(p => p.data);
}


async function SearchCore(inputs) {
    if (inputs.full_name && !inputs.text) inputs.text = inputs.full_name;
    var query = [Prismic.Predicates.at("document.type", "properties")]

    if (inputs.city && inputs.city.length > 0 && inputs.city[0] != fallback_city) {
        var city_ids = [];
        var cities = await window.cities;

        for (var i = 0; i < inputs.city.length; i++) {
            const city_id = cities.find(p => (inputs.city[i] && p.city_name.toLowerCase() == inputs.city[i].toLowerCase()));
            if (city_id) city_ids.push(city_id.id)
        }

        if (city_ids.length > 0) query.push(Prismic.Predicates.any("my.properties.city", city_ids));
        else {
            return { total_results_size: 0 }
        }
    }
    if (inputs.builder && inputs.builder.length > 0) {
        var builder_ids = [];
        var builders = await window.builders;

        for (var i = 0; i < inputs.builder.length; i++) {
            const builder_id = builders.find(p => p.builder_name.toLowerCase() == inputs.builder[i].toLowerCase());
            if (builder_id) builder_ids.push(builder_id.id);
        }
        if (builder_ids.length > 0) query.push(Prismic.Predicates.any("my.properties.builder_name", builder_ids))
        else return { total_results_size: 0 }
    }

    if (inputs.property_type && inputs.property_type.length > 0) {
        var type_ids = [];
        var property_types = await window.property_type;

        for (var i = 0; i < inputs.property_type.length; i++) {
            const type_id = property_types.find(p => p.property_type.toLowerCase() == inputs.property_type[i].toLowerCase());
            if (type_id) type_ids.push(type_id.id)
        }

        if (type_ids.length > 0) query.push(Prismic.Predicates.any("my.properties.property_type_group.property_type", type_ids));
        else return { total_results_size: 0 }
    }

    if (inputs.bhk && inputs.bhk.length > 0) {
        query.push(Prismic.Predicates.any("my.properties.floor_plans.bhk", inputs.bhk))
    }

    if (inputs.amenity && inputs.amenity.length > 0) {
        var amenity_ids = [];
        var amenity = await window.amenities;

        for (var i = 0; i < inputs.amenity.length; i++) {
            const amenity_id = amenity.find(p => p.amenity.toLowerCase() == inputs.amenity[i].toLowerCase());
            if (amenity_id) amenity_ids.push(amenity_id.id);
        }
        if (amenity_ids.length > 0) query.push(Prismic.Predicates.any("my.properties.amenities.amenities1", amenity_ids))
    }

    if (inputs.collections && inputs.collections.length > 0) {
        var collection_ids = [];
        var collection = await window.collections;

        for (var i = 0; i < inputs.collections.length; i++) {
            const collection_id = collection.results.find(p => p.data.name.toLowerCase() == inputs.collections[i].toLowerCase());
            if (collection_id) collection_ids.push(collection_id.id);
        }

        if (collection_ids.length > 0) query.push(Prismic.Predicates.any("my.properties.collections.collection", collection_ids))
    }

    if (inputs.status && inputs.status.length > 0) {
        query.push(Prismic.Predicates.any("my.properties.status", inputs.status));
    }

    if (inputs.budget) {
        if (inputs.budget.min) query.push(Prismic.Predicates.gt("my.properties.price_range_maximum", inputs.budget.min));
        if (inputs.budget.max) query.push(Prismic.Predicates.lt("my.properties.price_range_minimum", inputs.budget.max));
    }

    if (inputs.zoning && inputs.zoning.length > 0) {
        query.push(Prismic.Predicates.any("my.properties.zoning", inputs.zoning));

    }

    if (inputs.featured) {
        query.push(Prismic.Predicates.at("my.properties.featured", true));
    }

    if (inputs.offer_available) {
        query.push(Prismic.Predicates.at("my.properties.offer_available", true));
        const date = new Date();
        query.push(Prismic.Predicates.date.after("my.properties.offer_validity", Date.parse(date)));
    }

    const page = (inputs.page) ? inputs.page : 1;
    const results_per_page = (inputs.page_size) ? inputs.page_size : 12;
    const fetchLinks = ['builders.builder_name', 'city.city_name', 'property_types.property_type', 'property_types.icon']

    const fetchItem = (!inputs.fetchItem) ?
        ["properties.images", "properties.videos", "properties.full_name", "properties.rera_number", "properties.currency", "properties.featured", "properties.price_range_minimum", "properties.price_range_maximum", "properties.city", "properties.status", "properties.unit_size", "properties.offer_available", "properties.offer_validity", "properties.location", "properties.images", "properties.builder_name", "properties.property_type_group", "properties.floor_plans", "properties.alert_text"] :
        inputs.fetchItem;

    var extra = {}
    if (inputs.order) {
        switch (inputs.order) {
            case "Price low to high":
                extra = { orderings: '[my.properties.price_range_minimum]' }
                break;
            case "Price high to low":
                extra = { orderings: '[my.properties.price_range_maximum desc]' }
        }
    }

    var results = {};
    results = await PrismicClient.query(query, { fetch: fetchItem, fetchLinks: fetchLinks, page: page, pageSize: results_per_page, ...extra });
    var current_page = results.page;
    while(results.results_size < results_per_page && current_page < results.total_pages){
        current_page = current_page + 1;
        var temp = await PrismicClient.query(query, { fetch: fetchItem, fetchLinks: fetchLinks, page: current_page, pageSize: results_per_page, ...extra });
        results.results = [...new Set([...results.results, ...temp.results])]
    }
    if (inputs.text) {
        query.push(Prismic.Predicates.fulltext('document', inputs.text));
        const results_2 = await PrismicClient.query(query, { fetch: fetchItem, fetchLinks: fetchLinks, page: page, pageSize: results_per_page, ...extra });
        const tokens = inputs.text.split(" ");
        var results_2_filtered = rank_props(results_2.results, tokens)
        if (results_2.results_size < results_per_page) {
           
            const full_data = rank_props(results_2.results.concat(results.results), tokens)
            const ids = [...new Set(full_data.map(p => p.id))]


            const data = ids.map(p => {
                return full_data.find(q => q.id == p);
            })
            
            return {
                total_results_size: data.length,
                results: data,
            }
        }

        const full_data = results_2_filtered;
        const ids = [...new Set(full_data.map(p => p.id))]



        const data = ids.map(p => {
            return full_data.find(q => q.id == p);
        })
        return {
            total_results_size: data.length,
            results: data,
        }
    }


    return results
}

const SearchCache = new ThroughCache(SearchCore);

export async function Search(inputs) {
    return SearchCache.call(inputs);
}

async function citiesCore(inputs) {
    const results = await PrismicClient.query([
        Prismic.Predicates.at("document.type", "city")
    ], { pageSize: 100 });
    return results;
}
const CitiesCache = new ThroughCache(citiesCore, 48);
export async function getCities() {
    return CitiesCache.call("cities/");
}

async function getCollectionsCore(inputs) {
    const results = await PrismicClient.query([
        Prismic.Predicates.at("document.type", "collection")
    ], {
        pageSize: 100,
        fetchLinks: ['builders.builder_name', 'city.city_name', 'property_types.property_type', 'property_types.icon', "properties.full_name", "properties.rera_number", "properties.currency", "properties.featured", "properties.price_range_minimum", "properties.price_range_maximum", "properties.city", "properties.status", "properties.unit_size", "properties.offer_available", "properties.offer_validity", "properties.location", "properties.images", "properties.builder_name", "properties.property_type_group", "properties.floor_plans"],
        orderings: '[my.collection.order]'
    });

    return results;
}
const CollectionCache = new ThroughCache(getCollectionsCore, 48);
export async function getCollections() {
    return CollectionCache.call("collections/")
}

async function getBuilderNamesCore(inputs) {
    const results = await PrismicClient.query([
        Prismic.Predicates.at("document.type", "builders")
    ], { pageSize: 100 });

    return results;
}
const BuilderCache = new ThroughCache(getBuilderNamesCore, 48);
export async function getBuilderNames() {
    return BuilderCache.call("builders/")
}

async function getPropertyTypesCore(inputs) {
    const results = await PrismicClient.query([
        Prismic.Predicates.at("document.type", "property_types")
    ], { pageSize: 100, fetch: ['property_types.property_type', 'property_types.icon'] });

    return results;
}
const PropertyTypeCache = new ThroughCache(getPropertyTypesCore, 48);
export async function getPropertyTypes() {
    return PropertyTypeCache.call("property_types/")
}

async function getAmenitiesCore(inputs) {

    const results = await PrismicClient.query([
        Prismic.Predicates.at("document.type", "amenities")
    ], { pageSize: 100, fetch: ['amenities.amenity', 'amenities.icon'], orderings: '[amenities.amenity]' });

    return results;
}
const AmenityCache = new ThroughCache(getAmenitiesCore, 48);

export async function getAmenities() {
    return AmenityCache.call("amenities/");
}

export async function getFavList(inputs) {
    inputs = inputs.filter(p => p != null)
    const fetchLinks = ['builders.builder_name', 'city.city_name', 'property_types.property_type', 'property_types.icon']
    const fetchItem = ["properties.full_name", "properties.rera_number", "properties.currency", "properties.featured", "properties.price_range_minimum", "properties.price_range_maximum", "properties.city", "properties.status", "properties.unit_size", "properties.offer_available", "properties.offer_validity", "properties.location", "properties.images", "properties.builder_name", "properties.property_type_group", "properties.floor_plans"]
    const results = await PrismicClient.query([
        Prismic.Predicates.at("document.type", "properties"),
        Prismic.Predicates.in("document.id", inputs)
    ], { fetch: fetchItem, fetchLinks: fetchLinks, pageSize: 1000 });

    return results
}


async function GetBasicDocsCore(inputs) {
    const results = await PrismicClient.query([
        Prismic.Predicates.at('document.type', 'sitevariables')
    ]
    );

    return results.results[0].data;
}
const BasicDocsCache = new ThroughCache(GetBasicDocsCore, 48);
export async function GetBasicDocs() {
    return BasicDocsCache.call("Sitevars/")
}

async function GetSingletonCore(inputs) {
    const results = await PrismicClient.query([
        Prismic.Predicates.at('document.type', 'accreditation'),
        Prismic.Predicates.any('my.accreditation.type', [inputs]),

    ]
    );
    return results.results[0].data;
}
const SingleTonCache = new ThroughCache(GetSingletonCore, 48);

export async function GetSingleton(inputs) {
    return SingleTonCache.call(inputs);
}

async function GetFAQCore(inputs) {
    const results = await PrismicClient.query([
        Prismic.Predicates.at('document.type', 'faq'),
        Prismic.Predicates.any('my.faq.type', [inputs]),
    ]
    );
    return results.results[0].data;
}
const FAQCache = new ThroughCache(GetFAQCore, 48);

export async function GetFAQ(inputs) {
    return FAQCache.call(inputs);
}


export async function GetSiteData(key) {
    const results = await PrismicClient.query([
        Prismic.Predicates.at('document.type', 'sitedata'),
        Prismic.Predicates.any('my.sitedata.item', [key.toString()]),
    ]
    );

    return results.results[0].data.content;
}

export async function GetPageData(key) {
    const results = await PrismicClient.query([
        Prismic.Predicates.at('document.type', 'generic_page'),
        Prismic.Predicates.any('my.generic_page.page_link', [key.toString()]),
    ]
    );

    return results.results;
}


export async function GetCareers() {
    const results = await PrismicClient.query(
        Prismic.Predicates.at('document.type', 'careers'),
        { pageSize: 100 });
    return results.results
}

export async function GetPricing() {
    const results = await PrismicClient.query(
        Prismic.Predicates.at('document.type', 'pricing'),
        { pageSize: 100 });
    return results.results
}



export async function GetBlogDataPrismatic(idx) {
    const results = await PrismicClient.query(
        Prismic.Predicates.at('document.type', 'blogs'),
        { orderings: '[my.blogs.date desc]', pageSize: 1, page: idx, fetchLinks: ['team_members.name', 'team_members.image_link'], fetch: ['blogs.title', 'blogs.date', 'blogs.contents', 'blogs.image_link', 'blogs.preview_paragraph', 'blogs.author', 'blogs.tags'] }
    );

    return { data: results.results, "pages": results.total_pages }
}

export async function GetLinkFromIdx(idx) {
    const results = await PrismicClient.query(
        [Prismic.Predicates.at('document.type', 'blogs'),
        Prismic.Predicates.at("my.blogs.uid", idx.toString())
        ],
        { orderings: '[my.blogs.uid desc]', pageSize: 1, fetch: ['blogs.link_title'] }
    );

    return { data: results.results, "pages": results.total_pages }
}


export async function GetBlogFromName(name) {
    const results = await PrismicClient.query([
        Prismic.Predicates.at('document.type', 'blogs'),
        Prismic.Predicates.at('my.blogs.link_title', name)
    ],
        { orderings: '[my.blogs.uid desc]', pageSize: 1, fetchLinks: ['team_members.name', 'team_members.image_link', 'team_members.hide'], fetch: ['blogs.title', 'blogs.minutes', 'blogs.date', 'blogs.contents', 'blogs.image_link', 'blogs.preview_paragraph', 'blogs.author', 'blogs.tags'] }
    );

    return { data: results.results, "pages": results.total_pages }
}



export async function GetLatestBlogs(num_entries) {
    const page_number = 0;

    const results = await PrismicClient.query(
        Prismic.Predicates.at('document.type', 'blogs'),
        { orderings: '[my.blogs.uid desc]', pageSize: num_entries, page: page_number, fetch: ['blogs.title', 'blogs.link_title', 'blogs.date', 'blogs.image_link'] }
    );

    return results.results;
}

export async function GetBlogTitlesPrismatic(num_entries, page_number) {
    const results = await PrismicClient.query(
        Prismic.Predicates.at('document.type', 'blogs'),
        { orderings: '[my.blogs.date desc]', pageSize: num_entries, page: page_number, fetchLinks: ['team_members.name', 'team_members.image_link', 'team_members.hide'], fetch: ['blogs.title', 'blogs.link_title', 'blogs.minutes', 'blogs.date', 'blogs.image_link', 'blogs.preview_paragraph', 'blogs.author'] }
    );

    return { data: results.results, "pages": results.total_pages }
}




async function PropertyDataCore(query) {
    const next_filters = ["properties.full_name", "properties.not_assured_project", "properties.rera_number", "properties.currency", "properties.featured", "properties.price_range_minimum", "properties.price_range_maximum", "properties.city", "properties.status", "properties.unit_size", "properties.offer_available", "properties.offer_validity", "properties.location", "properties.images", "properties.builder_name", "properties.property_type_group", "properties.floor_plans"]
    const results = await PrismicClient.query(
        [Prismic.Predicates.at('document.type', 'properties'),
        Prismic.Predicates.at('my.properties.city', query.city),
        Prismic.Predicates.at('my.properties.full_name', query.name)
        ],
        {
            fetchLinks: [...next_filters, 'city.city_name', 'city.loan_interest_minimum', 'city.loan_interest_maximum', 'builders.builder_name', 'builders.total_projects', 'builders.ongoing_projects', 'builders.established_year', 'builders.logo_link', 'builders.banner_logo_link', 'builders.website_link', 'builders.description', 'property_types.property_type', 'property_types.icon', 'amenities.amenity', 'amenities.icon']
        }
    );

    return results.results;
}

const PropertyCache = new ThroughCache(PropertyDataCore)



export async function GetPropertyData(city, name) {
    return PropertyCache.call({
        city: city,
        name: name
    });
}


export async function GetEntriesPrismic(content_type, ordering) {
    const results = await PrismicClient.query(Prismic.Predicates.at("document.type", content_type), { orderings: ordering, pageSize: 100 });
    if (results) {
        const outputs = results.results.map(p => {
            const data = p.data; const uid = p.uid;
            return { ...data, "id": uid }
        });
        return outputs;
    }
    else {
        return null;
    }
}

