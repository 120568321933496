import React from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router';

export default function TinySearch() {
    const history = useHistory();
    function search_thing() {
        var boxes = document.getElementsByClassName("soorkie-search");

        if (boxes.length > 0) {
            if (window.innerWidth < 768) {
                var items = [];
                for (var i = 0; i < boxes.length; i++) {
                    if (boxes[i].id == "mobile") items.push(boxes[i])
                }
                if (items.length > 0) {
                    var box = items[0];

                    box.focus();
                    return;
                }

            }
            var items = [];
            for (var i = 0; i < boxes.length; i++) {
                if (boxes[i].id != "mobile") items.push(boxes[i])
            }
            if (items.length > 0) {
                var box = items[0];

                box.focus();
                return;
            }
            // var box = boxes[0];
            // box.focus();
        }
        else {
            history.push("/search");
            // window.open("/search", "_self")
        }
    }
    return (<>
        <div title="Search" onClick={search_thing} style={{ paddingRight: '1.5em', cursor: 'pointer', marginTop: 'auto', marginBottom: 'auto' }}>
            <svg width="22" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.5594 20.9995L16.7786 16.2101L21.5594 20.9995ZM19.428 9.8094C19.428 12.2119 18.4736 14.516 16.7748 16.2148C15.076 17.9136 12.7719 18.868 10.3694 18.868C7.96691 18.868 5.66282 17.9136 3.964 16.2148C2.26518 14.516 1.31079 12.2119 1.31079 9.8094C1.31079 7.40691 2.26518 5.10282 3.964 3.404C5.66282 1.70518 7.96691 0.750793 10.3694 0.750793C12.7719 0.750793 15.076 1.70518 16.7748 3.404C18.4736 5.10282 19.428 7.40691 19.428 9.8094V9.8094Z" stroke="white" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

        </div>
    </>
    )
}
