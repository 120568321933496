import React, { useState, useEffect, useRef } from 'react'
import { TransparentButton } from './Button'
import {
    Navbar, Dropdown, FormControl
} from 'react-bootstrap'
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth } from "../contexts/AuthContext";
import { HashLink } from 'react-router-hash-link';
import UserCorner, { profile_icon, heart_icon, ProfileIcon } from './UserCorner';
import { useData } from '../contexts/DataContext';
import "../css/header.css";
import Fav from './Fav';
import CitiesDropDown from './CitiesDropdown';
import AuthPopup from "./AuthPopup";
import TinySearch from './TinySearch';
import {
    colored_logo,
    india_flag,
    whatsapp_icon,
    phone_icon,
    whatsapp_icon_tiny,
    msg_logo_tiny,
    phone_icon_tiny,
    enq_button_tiny,
    facebook_icon_small_dark,
    insta_small_dark,
    linkedin_icon_small_dark,
    utube_small_dark,
    twitter_icon_small_dark,
    pinterest_icon_small_dark
} from './Icons';

import { Search } from "../classes/ContentProvider"

function OfferItems(props) {
    const [data, setData] = useState([]);
    const { city } = useData();
    useEffect(async () => {
        var outs = await Search({
            city: [city],
            fetchLinks: [],
            fetchItem: ['properties.offer_validity'],
            offer_available: true
        })

        if (!outs || !outs.results) return;
        var today = new Date();
        today.setHours(0, 0, 0, 0);

        outs = outs.results.map(p => p.data)
        outs = outs.filter(p => {
            var validity = new Date(p.offer_validity);
            return validity >= today
        })
        setData(outs)

    }, [])
    return ((data.length > 0) &&

        <div className="d-none d-lg-flex">
            <Link title="Offers" style={{ display: 'block', marginTop: 'auto', marginBottom: 'auto', marginRight: '1em', }} to="/offers">
                <img style={{ cursor: 'pointer', opacity: '0.8', height: '2em', filter: "invert() hue-rotate(180deg) " }} src="/Images/offer.gif" />
            </Link>

        </div>
    )
}


function PhoneHome(props) {
    const [data, setData] = useState({
        phone_number: "+919025127127",
        whatsapp_number: "+914443153537"
    });

    const phone_icon = (<svg style={{ marginTop: 'auto', marginBottom: 'auto' }} width="22" height="22" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path strokeWidth="0.9" d="M13.382 15.2149L13.6468 15.3135L13.8678 15.1376L16.076 13.3804L20.2677 16.0811L20.2742 16.0853L20.2809 16.0893C20.3754 16.1461 20.4553 16.2241 20.5145 16.3171C20.5737 16.4101 20.6104 16.5156 20.6218 16.6253C20.6331 16.735 20.6189 16.8458 20.5801 16.949C20.5413 17.0522 20.479 17.1449 20.3982 17.2199L20.3925 17.2252L20.387 17.2307L18.4007 19.1927L18.4007 19.1927C18.175 19.4158 17.8975 19.5794 17.5931 19.6692C17.2888 19.7589 16.9669 19.7719 16.6563 19.7069L16.6551 19.7067C12.8438 18.9195 9.33077 17.0764 6.51716 14.3877C3.76972 11.6739 1.86844 8.22135 1.04381 4.44858C0.978627 4.14753 0.99228 3.83474 1.08345 3.54051C1.17466 3.24614 1.34036 2.98033 1.5645 2.76884L1.56452 2.76886L1.5685 2.76502L3.62738 0.778811L3.63306 0.773327L3.63857 0.767667C3.71338 0.690792 3.80461 0.631837 3.90543 0.595216C4.00625 0.558595 4.11405 0.545257 4.22076 0.556201C4.32746 0.567146 4.43031 0.602088 4.5216 0.658413C4.61289 0.714737 4.69025 0.790984 4.7479 0.881444L4.74776 0.881535L4.75502 0.892302L7.53633 5.01652L5.74802 7.16598L5.55937 7.39273L5.66659 7.66752C6.3377 9.38749 7.38266 10.9368 8.72584 12.2034C10.0399 13.5345 11.629 14.5623 13.382 15.2149Z" stroke="white" />
    </svg>);

    useEffect(async () => {
        var data = await window.site_data;
        setData(data);
    }, [])

    return (<a title="Phone" className="capital-text phone-home" style={{ display: 'flex', color: 'white', textDecoration: 'none' }} href={"tel://" + data.phone_number} >
        {/* <div></div> */}
        <div style={{ marginTop: '0.1em', display: 'flex' }}>{phone_icon}</div>
        <div className="d-none d-xl-block" style={{ marginLeft: '10px', marginTop: 'auto', marginBottom: 'auto' }}>
            <span style={{ marginRight: "10px" }}>{data.phone_number}</span>{india_flag}
        </div>

    </a>)
}

function SidebarDropdown(props) {
    const [state, setState] = useState(false);
    const others = props.children.filter((p, idx) => idx != 0)

    const transform = "translateY(-100%) " + (state ? "rotateZ(180deg)" : "");
    return (<>
        <div onClick={() => setState(!state)} style={{ display: 'flex', cursor: 'pointer', fontWeight: (state ? 'bolder' : 'normal'), color: (state ? "var(--accent)" : "white") }} className="headerdrops">
            {props.children[0]}
            <div className="city-drops-svg" style={{ float: 'right', marginLeft: 'auto', height: '9px', marginTop: '0.7em' }}>
                <svg style={{ transition: 'var(--time)', transform: transform }} width="15" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.13635 1.18457L8.42175 8.46997L15.7072 1.18457" stroke="white" stroke-linecap="round" strokeWidth="1.5" stroke-linejoin="round" />
                </svg>
            </div>
        </div>
        <div style={{ maxHeight: state ? '196px' : '0', overflow: "auto", marginLeft: "20px" }} className="sub-menu-side">
            {others}
        </div>
    </>)
}




const left_arrow = (<svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.88284 14.8189C7.17629 15.1123 7.65111 15.1154 7.94839 14.8259C8.25085 14.5312 8.25446 14.0463 7.95642 13.7472L2.74285 8.51506L17.2823 8.51506C17.7016 8.51506 18.0415 8.17514 18.0415 7.75582C18.0415 7.33651 17.7016 6.99658 17.2823 6.99658L2.74285 6.99658L7.97336 1.77091C8.26291 1.48162 8.26278 1.01226 7.97305 0.723143C7.66319 0.413933 7.16141 0.414196 6.85188 0.723732L0.419764 7.15584C0.0884037 7.4872 0.0884037 8.02444 0.419764 8.3558L6.88284 14.8189Z" fill="white" />
</svg>)


function MessageBox(props) {
    if (!props.message || !props.link) return (<></>)

    const megalodon = (<svg style={{ marginRight: '10px', opacity: '0.8' }} width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill="var(--accent)" d="M19.907 2.33799C19.969 2.54599 20 2.76199 20 2.97899V14.456C19.9999 14.8062 19.918 15.1515 19.7609 15.4645C19.6038 15.7775 19.3758 16.0495 19.0951 16.2589C18.8144 16.4683 18.4886 16.6092 18.1438 16.6705C17.799 16.7318 17.4447 16.7118 17.109 16.612L11.595 14.972C11.2047 15.768 10.5611 16.4119 9.76531 16.8026C8.96949 17.1932 8.06647 17.3086 7.19798 17.1306C6.3295 16.9525 5.54471 16.4912 4.96681 15.8188C4.38891 15.1465 4.05061 14.3014 4.005 13.416L4 13.216L3.999 12.716L1.609 12.005C1.14402 11.8668 0.736149 11.582 0.446196 11.1931C0.156242 10.8043 -0.000266707 10.3321 3.41176e-07 9.84699V7.58599C0.000228393 7.10112 0.157081 6.6293 0.447194 6.2408C0.737308 5.8523 1.14516 5.56791 1.61 5.42999L17.11 0.823993C17.6818 0.654191 18.2976 0.718391 18.8221 1.00248C19.3466 1.28657 19.7368 1.7673 19.907 2.33899V2.33799ZM5.499 13.161L5.5 13.215C5.49947 13.7587 5.67621 14.2878 6.00342 14.722C6.33063 15.1562 6.79048 15.4719 7.31327 15.6213C7.83605 15.7706 8.39328 15.7455 8.90049 15.5496C9.4077 15.3538 9.83724 14.9979 10.124 14.536L5.499 13.161V13.161ZM17.536 2.26099L2.036 6.86599C1.88096 6.91215 1.745 7.0072 1.6484 7.13696C1.5518 7.26671 1.49975 7.42423 1.5 7.58599V9.84699C1.49975 10.0088 1.5518 10.1663 1.6484 10.296C1.745 10.4258 1.88096 10.5208 2.036 10.567L17.536 15.174C17.648 15.2073 17.7663 15.2141 17.8814 15.1936C17.9965 15.1731 18.1052 15.126 18.1988 15.0561C18.2925 14.9861 18.3685 14.8953 18.4207 14.7907C18.473 14.6862 18.5002 14.5709 18.5 14.454V2.97999C18.5 2.86319 18.4728 2.748 18.4204 2.64359C18.3681 2.53918 18.292 2.44845 18.1984 2.37862C18.1048 2.30879 17.9961 2.26179 17.8811 2.24137C17.7661 2.22094 17.6479 2.22767 17.536 2.26099Z" />
    </svg>
    )

    const neo_meg = (<svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path style={{ opacity: '0.5' }} d="M19.907 8.33799C19.969 8.54599 20 8.76199 20 8.97899V20.456C19.9999 20.8062 19.918 21.1515 19.7609 21.4645C19.6038 21.7775 19.3758 22.0495 19.0951 22.2589C18.8144 22.4683 18.4886 22.6092 18.1438 22.6705C17.799 22.7318 17.4447 22.7118 17.109 22.612L11.595 20.972C11.2047 21.768 10.5611 22.4119 9.76531 22.8026C8.96949 23.1933 8.06647 23.3086 7.19798 23.1306C6.3295 22.9525 5.54471 22.4911 4.96681 21.8188C4.38891 21.1465 4.05061 20.3014 4.005 19.416L4 19.216L3.999 18.716L1.609 18.005C1.14402 17.8668 0.736149 17.582 0.446196 17.1931C0.156242 16.8043 -0.000266707 16.3321 3.41176e-07 15.847V13.586C0.000228393 13.1011 0.157081 12.6293 0.447194 12.2408C0.737308 11.8523 1.14516 11.5679 1.61 11.43L17.11 6.82399C17.6818 6.65419 18.2976 6.71839 18.8221 7.00248C19.3466 7.28657 19.7368 7.7673 19.907 8.33899V8.33799ZM5.499 19.161L5.5 19.215C5.49947 19.7587 5.67621 20.2878 6.00342 20.722C6.33063 21.1562 6.79048 21.4719 7.31327 21.6213C7.83605 21.7706 8.39328 21.7455 8.90049 21.5496C9.4077 21.3538 9.83724 20.9979 10.124 20.536L5.499 19.161ZM17.536 8.26099L2.036 12.866C1.88096 12.9121 1.745 13.0072 1.6484 13.137C1.5518 13.2667 1.49975 13.4242 1.5 13.586V15.847C1.49975 16.0088 1.5518 16.1663 1.6484 16.296C1.745 16.4258 1.88096 16.5208 2.036 16.567L17.536 21.174C17.648 21.2073 17.7663 21.2141 17.8814 21.1936C17.9965 21.1731 18.1052 21.126 18.1988 21.0561C18.2925 20.9861 18.3685 20.8953 18.4207 20.7907C18.473 20.6862 18.5002 20.5709 18.5 20.454V8.97999C18.5 8.86319 18.4728 8.748 18.4204 8.64359C18.3681 8.53918 18.292 8.44845 18.1984 8.37862C18.1048 8.30879 17.9961 8.26179 17.8811 8.24137C17.7661 8.22094 17.6479 8.22767 17.536 8.26099Z"
            fill="white" />
        <path className="shine-1" d="M22.7365 0.897186V0.897186C22.779 2.64196 24.1833 4.04629 25.9281 4.08885V4.08885V4.08885C24.1887 4.14379 22.7914 5.54108 22.7365 7.28052V7.28052V7.28052C22.6732 5.54467 21.2806 4.15207 19.5448 4.08885V4.08885V4.08885C21.286 4.03805 22.6856 2.63842 22.7365 0.897186V0.897186Z"
            fill="var(--accent)" />
        <path className="shine-2" d="M25.9281 8.8826V8.8826C25.9518 9.85351 26.7332 10.635 27.7042 10.6587V10.6587V10.6587C26.7362 10.6892 25.9587 11.4668 25.9281 12.4347V12.4347V12.4347C25.8929 11.4688 25.118 10.6938 24.152 10.6587V10.6587V10.6587C25.121 10.6304 25.8998 9.85154 25.9281 8.8826V8.8826Z"
            fill="var(--accent)" />
    </svg>
    )

    const outs = (<div style={{ marginBottom: '1em', color: 'white', display: 'flex' }}>

        <div style={{ marginRight: '10px', marginLeft: 'auto' }}>{neo_meg}</div>
        <div style={{ opacity: "0.7", transform: "translateY(2px)", marginTop: 'auto', marginBottom: 'auto', marginRight: "auto" }}>
            <strong>{props.message}</strong>
        </div>
        {/* <TransparentButton value={(<>{neo_meg} {props.message}</>)} style={{ color: 'var(--accent)', width: '100%', padding: "0em 1em", letterSpacing: '0.5em', borderStyle: "1px solid var(--accent)", whiteSpace: 'nowrap', borderColor: "rgba(255, 255, 255, 0.7)", backgroundColor: "transparent", buttonColor: "btn-white" }} /> */}
    </div>
    )

    if (props.link.includes("https://")) return (
        <a style={{ textDecoration: 'none' }} href={props.link}>
            {outs}
        </a>
    )
    else
        return (
            <Link onClick={() => props.changeSidebar(false)} style={{ textDecoration: 'none' }} to={props.link}>
                <hr style={{ color: 'white' }}></hr>
                {outs}
            </Link>
        )
}


export default function Header(props) {
    const [collections, setCollections] = useState([]);
    const [sidebar, setSidebar] = useState(false);
    const { currentUser, logout } = useAuth();
    const [current_city, setCurrentcity] = useState();
    const [allcities, setAllcities] = useState([]);
    const { ReactGA, city, setCity } = useData();
    const [auth, setAuth] = useState(false);
    const [way, setWay] = useState(null);
    const [scrollTop, setScrollTop] = useState(0)

    const sidebar_ref = useRef();
    sidebar_ref.current = {
        sidebar: sidebar,
        top: scrollTop
    };

    const [data, setData] = useState({
        phone_number: "+919025127127",
        whatsapp_number: "+914443153537"
    });


    function chat_invoke() {
        try {
            document.getElementsByClassName("siq_bR")[0].click();
        }
        catch (err) {
        }
    }


    useEffect(() => {
        changeSidebar(false);
        ReactGA.pageview(window.location);
    }, [window.location])

    const login_btn = (<Link to="/login" className="login-btn">Login</Link>);



    const sidebar_toggle_normal_class = (sidebar) ? "sidebar-toggle-normal-in" : "sidebar-toggle-normal";
    const sidebar_class = (sidebar) ? "sidebar-out" : "sidebar-in";



    function done_auth() {
        setAuth(false)
    }


    const [view, setView] = useState((props.thresh == 0))
    const thresh = props.thresh ? props.thresh : 10;

    function updateScrollPosition(e) {
        if (e >= thresh * window.innerHeight / 100) {
            setView(true);
        }
        else {
            setView(false);
        }
    }

    function freeze_scroll(e) {
        requestAnimationFrame(()=> stop_scroll(e))
    }

    useEffect(async () => {
        var data = await window.site_data;
        setData(data);
        const coll = await window.collections;
        setCollections(coll.results);
        const cities = await window.cities;
        setAllcities(cities)

        if (props.thresh == 0) return;
        let lastKnownScrollPosition = 0;
        let ticking = false;
        document.addEventListener('scroll', function (e) {
            lastKnownScrollPosition = window.scrollY;

            if (!ticking) {
                window.requestAnimationFrame(function () {
                    updateScrollPosition(lastKnownScrollPosition);
                    ticking = false;
                });

                ticking = true;
            }
        });

        

    }, []);
    
    useEffect(() => {
        document.body.addEventListener('wheel', freeze_scroll);

        return function cleanupListener() {
            document.body.removeEventListener('wheel', freeze_scroll)
        }
    }, [window.location]);
    


    function changeSidebar(e) {
        if (e) {
            document.body.classList.add('noscroll');
            setScrollTop(window.pageYOffset || document.documentElement.scrollTop)
        }
        else {
            document.body.classList.remove('noscroll');
            
        }
        setSidebar(e)
    }

    const whatsapp_message = "Hi, I would like to know more about your services.";

    function stop_scroll(e){
        if(!sidebar_ref.current.sidebar) return;
        setTimeout(() => {
            window.scrollTo(0, sidebar_ref.current.top)
        }, 50)
        
        e.stopPropagation();
        e.preventDefault();
        e.returnValue = false;
        return false;
    }


    return (
        <div data-scroll={view ? "true" : "false"} className="top-header-soorkie">
            <AuthPopup way={way} done={done_auth} active={auth} />
            <div  className={sidebar_class}>
                <div style={{ color: 'white', position: 'absolute', right: '2em', top: '3em', cursor: 'pointer' }} onClick={() => changeSidebar(false)} className="close-button">
                    <div style={{ transform: 'rotateZ(45deg)' }} className="close-button-line"></div>
                    <div style={{ transform: 'rotateZ(-45deg)' }} className="close-button-line"></div>
                </div>
                <div style={{ display: 'flex' }}>

                    <Link to="/">
                        <div style={{ marginLeft: '0', marginRight: 'auto' }}>
                            <img style={{ height: '45px', minWidth: "150px" }} src={(data.icon && data.icon.url) ? data.icon.url : "/Images/full_icon.webp"} />
                        </div>
                    </Link>

                </div>
                <hr></hr>
                <div className="capital-text">
                    <SidebarDropdown>
                        <div className="page-linked-head" >
                            {city}
                        </div>
                        {allcities.map(p => {
                            return (
                                <div onClick={() => { setCity(p.city_name); setCurrentcity(p.city_name) }} style={{ textAlign: 'left', display: 'block', cursor: 'pointer' }} className="page-linked">
                                    {p.city_name}
                                </div>
                            )
                        })}

                    </SidebarDropdown>
                </div>
                <hr style={{ marginTop: '0' }}></hr>
                <div style={{ marginTop: '2em' }} className="capital-text">

                    <Link to="/" className="page-linked d-block d-lg-none">
                        Home
                    </Link>


                    <SidebarDropdown>
                        <div className="page-linked-head gold-text" >
                            HK Club Gold
                        </div>
                        <Link onClick={() => changeSidebar(false)} to='/gold' className="page-linked">
                            Membership Benefits
                        </Link>
                        <div onClick={() => { setWay(null); setAuth(true) }} style={{ cursor: 'pointer' }} className="page-linked">
                            Login
                        </div>
                        <div onClick={() => { setWay("register"); setAuth(true) }} style={{ cursor: 'pointer' }} className="page-linked">
                            Register
                        </div>

                        {/* <Link onClick={() => changeSidebar(false)} to='/login' className="page-linked">
                            Login
                        </Link> */}
                        {/* <Link onClick={() => changeSidebar(false)} to='/register' className="page-linked">
                            Register
                        </Link> */}


                    </SidebarDropdown>

                    <SidebarDropdown>
                        <div className="page-linked-head" >
                            Buy
                        </div>
                        <Link onClick={() => changeSidebar(false)} to='/search/{"city":["All cities"], "property_type":["Apartments"]}' className="page-linked">
                            Apartment
                        </Link>
                        <Link onClick={() => changeSidebar(false)} to='/search/{"city":["All cities"], "property_type":["Villa"]}' className="page-linked">
                            Villa
                        </Link>
                        <Link onClick={() => changeSidebar(false)} to='/search/{"city":["All cities"], "property_type":["Villa Plots"]}' className="page-linked">
                            Land
                        </Link>
                        <Link onClick={() => changeSidebar(false)} to="/search" className="page-linked">
                            All Properties
                        </Link>
                        <Link onClick={() => changeSidebar(false)} to='/search/{"city":["All cities"]}' className="page-linked">
                            All Locations
                        </Link>

                    </SidebarDropdown>

                    <SidebarDropdown>
                        <div className="page-linked-head" >
                            Curated Collections
                        </div>
                        {collections.map(p => {
                            return (
                                <Link onClick={() => changeSidebar(false)} to={"/collections/" + p.data.name} className="page-linked">
                                    {p.data.name}
                                </Link>

                            )
                        })}
                    </SidebarDropdown>
                    <SidebarDropdown>
                        <div className="page-linked-head" >
                            About
                        </div>
                        <Link onClick={() => changeSidebar(false)} to="/about" className="page-linked">
                            Our Team
                        </Link>
                        <HashLink onClick={() => changeSidebar(false)} to="/#reviews" className="page-linked">
                            Testimonials
                        </HashLink>
                        <Link onClick={() => changeSidebar(false)} to="/accreditations&associations" className="page-linked">
                            Accreditation & Associations
                        </Link>
                        <Link onClick={() => changeSidebar(false)} to="/awards&press" className="page-linked">
                            Awards & Press
                        </Link>
                        <Link onClick={() => changeSidebar(false)} to="/blogs" className="page-linked">
                            Blogs
                        </Link>
                    </SidebarDropdown>

                    <SidebarDropdown>
                        <div className="page-linked-head" >
                            Resources
                        </div>
                        <Link onClick={() => changeSidebar(false)} to="/guide" className="page-linked">
                            Home buyer's guide
                        </Link>
                        <Link onClick={() => changeSidebar(false)} to="/faq" className="page-linked">
                            FAQ
                        </Link>
                        <Link onClick={() => changeSidebar(false)} to="/pricing" className="page-linked">
                            Pricing
                        </Link>
                        <Link onClick={() => changeSidebar(false)} to="/emi" className="page-linked">
                            EMI Calculator
                        </Link>
                        <Link onClick={() => changeSidebar(false)} to="/vastu" className="page-linked">
                            Vastu Tips
                        </Link>
                        <Link onClick={() => changeSidebar(false)} to="/nri" className="page-linked">
                            NRI Services
                        </Link>

                        <Link onClick={() => changeSidebar(false)} to="/terms" className="page-linked">
                            Terms of use
                        </Link>
                        <Link onClick={() => changeSidebar(false)} to="/sitemap" className="page-linked">
                            Sitemap
                        </Link>
                        <Link onClick={() => changeSidebar(false)} to="/privacy" className="page-linked">
                            Privacy Policy
                        </Link>
                    </SidebarDropdown>

                    <Link onClick={() => changeSidebar(false)} to="/careers" className="page-linked">
                        Careers
                    </Link>
                    <MessageBox changeSidebar={() => changeSidebar(false)} message={data.message_text} link={data.message_text_link} />




                </div>

                <div style={{ padding: '2em', paddingBottom: 0, position: 'absolute', bottom: 0, left: 0, width: '100%', background: 'black', zIndex: '2' }}>


                    <center>
                        <Link style={{ textDecoration: 'none' }} to="/contact">
                            <div style={{ marginBottom: '1.5em', opacity: '0.5', color: "white" }} className="capital-text">Contact us</div>
                        </Link>
                        <div className="bottom-logos" style={{ display: 'flex', justifyContent: 'center' }}>

                            <div onClick={() => chat_invoke()}> {msg_logo_tiny}</div>
                            <div>
                                <a target="_blank" style={{ textDecoration: 'none' }} href={"tel://" + data.phone_number} >
                                    {phone_icon_tiny}
                                </a>
                            </div>
                            <div>
                                <a target="_blank" style={{ textDecoration: 'none' }} href={"https://wa.me/" + data.whatsappbot + "?text=" + whatsapp_message} >
                                    {whatsapp_icon_tiny}
                                </a>
                            </div>
                            <div>
                                <Link style={{ textDecoration: "none" }} to="/contact">
                                    {enq_button_tiny}
                                </Link>
                            </div>
                        </div>
                    </center>
                    <hr style={{ margin: '0.7em 0' }}></hr>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <a target="_blank" href={data.facebook_link}>
                                {facebook_icon_small_dark}
                            </a>
                        </div>
                        <div>
                            <a target="_blank" href={data.instagram_link}>
                                {insta_small_dark}
                            </a>
                        </div>

                        <div>
                            <a target="_blank" href={data.linkedin_link}>

                                {linkedin_icon_small_dark}
                            </a>
                        </div>
                        <div>
                            <a target="_blank" href={data.youtube_link}>

                                {utube_small_dark}
                            </a>
                        </div>
                        <div>
                            <a target="_blank" href={data.twitter_link}>

                                {twitter_icon_small_dark}
                            </a>
                        </div>
                        <div>
                            <a target="_blank" href={data.pinterest_link}>
                                {pinterest_icon_small_dark}
                            </a>
                        </div>
                    </div>

                    <div className="capital-text" style={{ padding: '1em 0', opacity: "0.5" }}>
                        <center>&copy; All rights reserved</center>
                    </div>
                </div>

            </div>
            <div style={{ zIndex: '3', position: 'relative' }} className="nav-wrap">
                <Navbar style={{ background: "rgba(0,0,0,0)", zIndex: "3", width: "100%", position: 'relative' }} expand="lg" >
                    <div style={{ display: "flex" }} className="navbar-left capital-text">
                        <div onClick={() => changeSidebar(true)} style={{ cursor: 'pointer' }} className={sidebar_toggle_normal_class}>
                            |||
                        </div>
                        <div style={{ marginLeft: '15px', fontFamily: "var(--gen-font)", fontSize: "13px" }} className=" gen-text">
                            {/* {colored_logo} */}
                            <Link to="/">
                                <div className="d-none d-md-block">
                                    <img style={{ height: '32px', transform: 'translateY(-5px)' }} src={(data.icon && data.icon.url) ? data.icon.url : "/Images/full_icon.webp"} />
                                </div>
                                <div className="d-block d-md-none">
                                    <img style={{ height: '24px', transform: 'translateY(-2px)' }} src={(data.icon && data.icon.url) ? data.icon.url : "/Images/full_icon.webp"} />
                                </div>
                            </Link>

                        </div>
                        <div style={{ margin: 'auto', marginRight: "1.5em", marginLeft: '1.5em', display: 'flex' }} className="d-none d-md-flex capital-text">
                            <CitiesDropDown />
                        </div>



                    </div>
                    {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                    <Navbar.Collapse style={{ width: "100%", position: "absolute" }}>
                        <div className="navbar-center">
                            <Link to="/" className="navbar-item capital-text">
                                Home
                            </Link>
                            <Link to="/about" className="navbar-item capital-text">
                                About
                            </Link>

                            <Link to="/contact" className="navbar-item capital-text">
                                Contact us
                            </Link>

                        </div>
                    </Navbar.Collapse>
                    <div className="navbar-right" style={{ display: 'flex' }}>

                        <PhoneHome />
                        <OfferItems />
                        <TinySearch />
                        <Fav />
                        {
                            (currentUser) ? (<UserCorner />) :
                                (<>

                                    <div title="Login" style={{ marginTop: "auto", marginBottom: "auto", cursor: 'pointer' }} onClick={() => setAuth(true)} >

                                        <ProfileIcon />

                                    </div>
                                </>
                                )
                        }
                    </div>
                </Navbar>
            </div>
        </div >
    )
}
