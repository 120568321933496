import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import "../css/read_more.css";


export default function ShowHide(props) {
    const [state, setState] = useState(props.show);

    const plus = (<svg stroke={props.stroke ? props.stroke : 'var(--accent)'} width="20" height="20" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.9009 30.8669V7.13318M7.03403 19H30.7677H7.03403Z" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
        <circle cx="18.9009" cy="19" r="17.7673" stroke-width="1" />
    </svg>
    )

    const mina = (<svg  width="20" height="20" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.03403 19.4367H30.7677" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
        <circle cx="18.9009" cy="19.4367" r="17.7673" stroke-width="1" />
    </svg>
    )

    const transform = "" + (state ? "rotateZ(180deg)" : "");
    const icon = (<svg stroke={props.stroke ? props.stroke : 'var(--accent)'} style={{ transition: 'var(--time)', transform: transform }} width="15" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.13635 1.18457L8.42175 8.46997L15.7072 1.18457" stroke-linecap="round" strokeWidth="1.5" stroke-linejoin="round" />
    </svg>)

    if (props.ignore) return (
        <>
            {props.title}
            {props.children}
        </>
    )
    return (
        <>
            {/* <hr></hr> */}
            <div onClick={() => setState(!state)} className="capital-text" style={{ cursor: 'pointer', display: 'flex', color: 'var(--accent)' }}>
                <div >
                    {(props.show_title && props.hide_title)? (state? props.hide_title : props.show_title) : props.title}
                    {/* {props.title} */}
                </div>
                <div style={{ marginLeft: 'auto' }}>
                    {icon}
                    {/* {state ? mina : plus} */}
                </div>
            </div>

            <div className={state ? 'sh-show' : 'sh-hide'}>
                {props.children}
            </div>
            {/* <hr></hr> */}

        </>
    )
}
