// file App.js

import React, { Suspense, lazy } from 'react'
import Landing from "./pages/Landing";
import { AuthProvider } from "./contexts/AuthContext";
import { CookiesProvider } from "react-cookie";
import { FormProvider } from "./contexts/FormContext"

import {
	BrowserRouter as Router,
	Switch,
	Route,
} from "react-router-dom";

// import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/theme.css";

import { DataProvider } from './contexts/DataContext';
import Admin from './pages/Admin';
import GetButton from './components/GetButton';
import Disclaimer from './components/Disclaimer';
import { ToastContainer } from 'react-toastify';

// import 'react-toastify/dist/ReactToastify.css';
// minified version is also included
import 'react-toastify/dist/ReactToastify.min.css';
import SearchRedirect from './components/SearchRedirect';


const Services = lazy(() => import("./pages/Services"))
const AboutUs = lazy(() => import("./pages/AboutUs"))
const Person = lazy(() => import("./pages/Person"))
const Property = lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "property" */ "./pages/Property"))
const City = lazy(() => import("./pages/City"))
const Builder = lazy(() => import("./pages/Builder"))

const Collection = lazy(() => import("./pages/Collection"))
const BlogPage = lazy(() => import("./pages/BlogPage"))
const BlogIndividual = lazy(() => import("./pages/BlogIndividual"))
const BlogItem = lazy(() => import("./pages/BlogFromName"))

const LogIn = lazy(() => import("./pages/LogIn"))
const Register = lazy(() => import("./pages/Register"))
const Profile = lazy(() => import("./pages/Profile"))
// const Comms = lazy(() => import("./components/Comms"))
// const Disclaimer = lazy(() => import("./components/Disclaimer"));
const Shortlists = lazy(() => import("./pages/Shortlists"))
const NotFound = lazy(() => import("./pages/NotFound"))
const LogOut = lazy(() => import("./pages/LogOut"))
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"))
const ContactUs = lazy(() => import("./pages/ContactUs"));
const Privacy = lazy(() => import("./pages/Privacy"));
const Terms = lazy(() => import("./pages/Terms"));
const Faq = lazy(() => import("./pages/Faq"));
const NriCorner = lazy(() => import("./pages/Nri"));
const Phone = lazy(() => import("./pages/Phone"));
const Search = lazy(() => import("./pages/Search"));
const EMI = lazy(() => import("./pages/Emi"));
const GoldClub = lazy(() => import("./pages/GoldClub"));
const Offers = lazy(() => import("./pages/Offers"));
const Accreditation = lazy(() => import("./pages/Accreditation"));
const Associations = lazy(() => import("./pages/Associations"));
const Press = lazy(() => import("./pages/Press"));
const Awards = lazy(() => import("./pages/Awards"));
const AwardYear = lazy(() => import("./pages/AwardYear"));
const Accnass = lazy(() => import("./pages/Accnass"));
const FeaturedFull = lazy(() => import("./pages/FeaturedFull"));
const Awnpress = lazy(() => import("./pages/Awnpress"));
const Guide = lazy(() => import("./pages/Guide"));
const Careers = lazy(() => import("./pages/Careers"));
const Apply = lazy(() => import("./pages/CareersApply"));
const Vastu = lazy(() => import("./pages/VastuTips"));
const Sitemap = lazy(() => import("./pages/Sitemap"));
const GenericPage = lazy(() => import("./pages/GenericPage"));
const Pricing = lazy(() => import("./pages/Pricing"));

function App() {
	return (
		<div className="App">
			<Router>
				<CookiesProvider>
					<DataProvider>
						<AuthProvider>
							<FormProvider>
								<ToastContainer autoClose={3000} closeOnClick />
								<Disclaimer />
								<GetButton />

								<Switch>

									<Route path="/" exact>
										<Landing />
									</Route>
									<Route path="/login" exact>
										<Suspense fallback={<></>}>
											<LogIn />
										</Suspense>
									</Route>
									<Route path="/contact" exact>
										<Suspense fallback={<></>}>
											<ContactUs />
										</Suspense>
									</Route>
									<Route path="/register" exact>
										<Suspense fallback={<></>}>
											<Register />
										</Suspense>
									</Route>
									<Route path="/profile" exact>
										<Suspense fallback={<></>}>
											<Profile />
										</Suspense>
									</Route>
									<Route path="/shortlists" exact>
										<Suspense fallback={<></>}>
											<Shortlists />
										</Suspense>
									</Route>
									<Route path="/logout" exact>
										<Suspense fallback={<></>}>
											<LogOut />
										</Suspense>
									</Route>
									<Route path="/about" exact>
										<Suspense fallback={<></>}>
											<AboutUs />
										</Suspense>
									</Route>
									<Route path="/forgot_password" exact>
										<Suspense fallback={<></>}>
											<ForgotPassword />
										</Suspense>
									</Route>
									<Route path="/about/:name" exact>
										<Suspense fallback={<></>}>
											<Person />
										</Suspense>
									</Route>
									<Route path="/services" exact>
										<Suspense fallback={<></>}>
											<Services />
										</Suspense>
									</Route>
									<Route path="/blogs" exact>
										<Suspense fallback={<></>}>
											<BlogPage />
										</Suspense>
									</Route>
									<Route path="/blogs/:name" exact>
										<Suspense fallback={<></>}>
											<BlogItem />
										</Suspense>
									</Route>
									<Route path="/blogs/:id/:name" exact>
										<Suspense fallback={<></>}>
											<BlogIndividual />
										</Suspense>
									</Route>
									<Route path="/property/:city/featured" exact>
										<Suspense fallback={<></>}>
											<FeaturedFull />
										</Suspense>
									</Route>
									<Route path="/property/:city/:name" exact>
										<Suspense fallback={<></>}>
											<Property />
										</Suspense>
									</Route>

									<Route path="/property/:city" exact>
										<Suspense fallback={<></>}>
											<City />
										</Suspense>
									</Route>

									<Route path="/builders/:builder" exact>
										<Suspense fallback={<></>}>
											<Builder />
										</Suspense>
									</Route>

									<Route path="/collections/:collection" exact>
										<Suspense fallback={<></>}>
											<Collection />
										</Suspense>
									</Route>

									<Route path="/searchbox/:text" exact>
										<SearchRedirect />
									</Route>

									<Route path="/search/:q" exact>
										<Suspense fallback={<></>}>
											<Search />
										</Suspense>
									</Route>

									<Route path="/search" exact>
										<Suspense fallback={<></>}>
											<Search />
										</Suspense>
									</Route>

									<Route path="/privacy" exact>
										<Suspense fallback={<></>}>
											<Privacy />
										</Suspense>
									</Route>
									<Route path="/terms" exact>
										<Suspense fallback={<></>}>
											<Terms />
										</Suspense>
									</Route>
									<Route path="/pricing" exact>
										<Suspense fallback={<></>}>
											<Pricing />
										</Suspense>
									</Route>
									<Route path="/faq" exact>
										<Suspense fallback={<></>}>
											<Faq />
										</Suspense>
									</Route>
									<Route path="/nri" exact>
										<Suspense fallback={<></>}>
											<NriCorner />
										</Suspense>
									</Route>
									<Route path="/vastu" exact>
										<Suspense fallback={<></>}>
											<Vastu />
										</Suspense>
									</Route>
									<Route path="/phone">
										<Suspense fallback={<></>}>
											<Phone />
										</Suspense>
									</Route>
									<Route path="/admin/" exact>
										<Suspense fallback={<></>}>
											<Admin />
										</Suspense>
									</Route>
									<Route path="/emi/" exact>
										<Suspense fallback={<></>}>
											<EMI />
										</Suspense>
									</Route>
									<Route path="/gold" exact>
										<Suspense fallback={<></>}>
											<GoldClub />
										</Suspense>
									</Route>
									<Route path="/offers" exact>
										<Suspense fallback={<></>}>
											<Offers />
										</Suspense>
									</Route>


									
									<Route path="/accreditations&associations/" exact>
										<Suspense fallback={<></>}>
											<Accnass />
										</Suspense>
									</Route>
									
									<Route path="/accreditations&associations/associations" exact>
										<Suspense fallback={<></>}>
											<Associations />
										</Suspense>
									</Route>
									<Route path="/accreditations&associations/accreditation" exact>
										<Suspense fallback={<></>}>
											<Accreditation />
										</Suspense>
									</Route>
									<Route path="/awards&press/" exact>
										<Suspense fallback={<></>}>
											<Awnpress />
										</Suspense>
									</Route>
									<Route path="/awards&press/press" exact>
										<Suspense fallback={<></>}>
											<Press />
										</Suspense>
									</Route>
									<Route path="/awards&press/awards" exact>
										<Suspense fallback={<></>}>
											<Awards />
										</Suspense>
									</Route>
									<Route path="/awards&press/awards/:year" exact>
										<Suspense fallback={<></>}>
											<AwardYear />
										</Suspense>
									</Route>
									<Route path="/guide" exact>
										<Suspense fallback={<></>}>
											<Guide />
										</Suspense>
									</Route>
									<Route path="/careers" exact>
										<Suspense fallback={<></>}>
											<Careers />
										</Suspense>
									</Route>
									<Route path="/careers/:job" exact>
										<Suspense fallback={<></>}>
											<Apply />
										</Suspense>
									</Route>
									<Route path="/sitemap" exact>
										<Suspense fallback={<></>}>
											<Sitemap />
										</Suspense>
									</Route>
									<Route path="/page/:link" exact>
										<Suspense fallback={<></>}>
											<GenericPage />
										</Suspense>
									</Route>
									<Route path="">
										<Suspense fallback={<></>}>
											<NotFound />
										</Suspense>
									</Route>


								</Switch>
							</FormProvider>
						</AuthProvider>
					</DataProvider>
				</CookiesProvider>
			</Router>
		</div >
	);
}

export default App;
