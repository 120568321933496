import React, { useState, useEffect, useRef } from 'react'
import "../css/alter.css"


export default function AlternatingIcon(props) {
    const initalState = 0;
    const [state, setState] = useState(0);
    const counterRef = useRef(initalState);

    useEffect(() => {
        counterRef.current = state;
    })

    useEffect(() => {
        setInterval(() => {
            window.requestAnimationFrame(() => {
                var next = counterRef.current + 1;
                if (next == props.children.length) next = 0;
                setState(next);
            })

        }, props.ttl ? props.ttl : 2000);
    }, [])
    return (
        <div style={{ position: 'relative' }}>
            {props.children.map((p, idx) => {
                return (<div style={{ position: 'absolute' }} className={(idx == state) ? 'alter-show' : 'alter-hide'}>
                    {p}
                </div>)
            })}
        </div>
    )
}
